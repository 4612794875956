// React Imports
import { FC, useState, useEffect } from "react";

// UI Imports
import { Box, InputAdornment, Switch, TextField } from "@mui/material";
import { IoMdSearch } from "react-icons/io";

// Functional Imports
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import STRINGS from "../../utils/Strings";
import Functions from "../../utils/Functions";

// Component Imports
import ButtonFilled from "../../common/ButtonFilled";
import Breadcrumbs from "../../common/Breadcrumbs";
import Tabsection from "./Tabsection";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface EditJobProps {
  isTesting?: boolean;
}

const EditJob: FC<EditJobProps> = (props) => {
  const navigate = useNavigate();
  const Params = useParams();

  const [activeJobs, setActiveJobs] = useState(false);
  const [jobData, setJobData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const jobID = Params.jobid;

  useEffect(() => {
    setLoading(true);
    API.getJobByID(jobID)
      .then((response) => {
        if (response?.status === 200) {
          let data = response?.entity;
          let postingdate = Functions.DateFunctions.formatDateObject(
            data.postingDate,
            "MMM DD YYYY"
          );
          let location =
            data?.city + ", " + data?.stateCode + ", " + data?.countryCode;
          let skilldata = Functions.ValueRetrieve.getSkillandLicenses(
            data?.manualData
          );
          let trimjd = data.jobDesc
            ? data.jobDesc.replaceAll("<p><br></p>", "")
            : "";
          setJobData({
            appjobid: data?.stafflineJobId,
            referenceNumber: data?.referenceNumber,
            jobid: jobID,
            jobTitle: data?.jobTitle,
            numberOfOpenings: data?.numberOfOpening,
            skillSet: skilldata?.skills,
            skillarr: skilldata?.skillsarr ? skilldata?.skillsarr : [],
            zipCode: data?.zipCode,
            billRate: data?.billRatePckg?.billRate,
            salaryRange: data?.package?.packageRange?.range,
            stateName: data?.stateName,
            city: data?.city,
            countryName: data?.countryName,
            stateId: data?.stateId,
            countryId: data?.countryId,
            stateCode: data?.stateCode,
            countryCode: data?.countryCode,
            cityId:data?.cityId,
            jobType: data?.jobType,
            location: location,
            jobPostedDate: postingdate,
            hiringManager: data?.hiringManagerName,
            jobStatus: data?.jobStatus,
            interviewtype: data?.interviewMode,
            modeofwork: data?.modeofWork,
            jobDescription: trimjd,
            ModifyJobOldNewValue: "",
          });
          setActiveJobs(data?.jobStatus == "Active" ? true : false);
        }
        setLoading(false);
      })
      .catch((e) => {
        Toasts.error(e);
        setJobData({});
        setLoading(false);
      });
  }, []);

  const formik = useFormik({
    initialValues: jobData,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      jobTitle: Yup.string()
        .max(100, STRINGS.CREATE_JOB.TITLE_LENGTH_ERROR_MSG)
        .required(STRINGS.CREATE_JOB.FILL_REQ_FIELD_TEXT),
      city: Yup.string().required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
      numberOfOpenings: Yup.number()
        .positive("It must be greater than zero")
        .required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
      jobType: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      zipCode: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      billRate: Yup.string().when("jobType", {
        is: (jobType: any) => jobType === "C" || jobType === "R",
        then: (schema) =>
          schema.required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
      }),
      salaryRange: Yup.string()
        .when("jobType", {
          is: (jobType: any) => jobType === "F" || jobType === "R",
          then: (schema) =>
            schema.required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
        })
        .nullable(),
      interviewtype: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
    }),
    onSubmit: (values) => {
      values.ModifyJobOldNewValue = Functions.ValueRetrieve.modifyCurrentJob(
        values,
        jobData
      );

      setTimeout(() => {
        updateJob(values);
      }, 1000);
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      let valuesPass = {
        jobid: "VA24004906121",
        jobTitle: "Dot Net",
        numberOfOpenings: 4,
        skillSet: "java,php,js",
        skillarr: ["java", "php", "js"],
        jobDescription:
          "<br/><p>Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.Enhance JD for a refined and improved job description.</p><br/>",
        zipCode: "452010",
        billRate: 0,
        salaryRange: "1000.00",
        stateName: "Madhya Pradesh",
        city: "Indore",
        countryName: "India",
        cityId: 83683,
        countryId:1,
        stateId:32,
        jobType: "R",
        location: "Indore, MP, IN",
        jobPostedDate: "Jul 29 2024",
        hiringManager: "",
        jobStatus: "In Review",
        interviewtype: 8,
        modeofwork: "onsite",
      };
      formik.setValues(valuesPass).then(() => {
        formik.handleSubmit();
      });
      chnageactivejob();
      Functions.ValueRetrieve.modifyCurrentJob(valuesPass, valuesPass);
      updateJob(valuesPass);
    }
  }, []);

  const chnageactivejob: any = () => {
    setActiveJobs((activeJobs) => !activeJobs);
    let newStatus = activeJobs ? "Inactive" : "Active";
    setLoading(true);

    API.updateJobStatus(jobID, newStatus)
      .then((response) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const updateJob = (values: any) => {
    console.log('valuesedit',values)
    if (values?.jobDescription?.length > 0) {
      setLoading(true);
      API.UPDATEJOB(values)
        .then((response) => {
          if (response?.status === 200) {
            Toasts.success(
              "Job " + response?.entity?.id + " has been updated successfully!"
            );
            navigate(Paths.JOB + Paths.VIEW_JOB + "/" + response?.entity?.id);
          }
          setLoading(false);
        })
        .catch((e) => {
          Toasts.error(e);
          setLoading(false);
        });
    }
  };

  return (
    <Box
      data-testid="edit-job-page"
      sx={{
        height: "90vh",
        overflowY: "scroll",
        width: "100%",
        backgroundColor: Colors.LightWhite,
      }}
    >
      {loading && <Loader />}
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          pl: 4,
          display: "flex",
          py: 1,
          backgroundColor: Colors.White,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.APPLICATIONS, name: "Home" },
            { path: Paths.JOBS, name: "Jobs" },
            { name: jobID },
          ]}
        />
        <TextField
          placeholder="Search"
          size="small"
          variant="outlined"
          sx={{
            paddingRight: 0,
            marginRight: 1.5,
            fontFamily: "Roboto",
            width: 300,
            backgroundColor: Colors.White,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoMdSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          p: 4,
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          py: 1,
          alignItems: "center",
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          {STRINGS.COMMONSTR.DETAIL}
        </Text>
        <ButtonFilled
          title={STRINGS.COMMONSTR.SAVE}
          onClick={formik.handleSubmit}
        />
      </Box>
      <Box
        sx={{
          p: 2,
          backgroundColor: Colors.White,
          pl: 4,
          ml: 4,
          mr: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              mr: 2,
              width: "17%",
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.JOB_ID}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {jobID}
            </Text>
          </Box>
          <Box
            sx={{
              mr: 2,
              width: "17%",
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.HIRING_MANAGER}
            </Text>
            <Text fontSize={14} fontWeight={700}>
              {formik?.values.hiringManager}
            </Text>
          </Box>
          <Box
            sx={{
              mr: 2,
              width: "17%",
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.COMMONSTR.STATUS}
            </Text>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flax-start",
                alignItems: "center",
                p: 0,
              }}
            >
              {formik?.values.jobStatus != "In Review" ? (
                <>
                  <div>
                    <Text fontWeight={700} fontSize={14}>
                      Active
                    </Text>
                  </div>
                  <Switch
                    checked={activeJobs}
                    onChange={chnageactivejob}
                    sx={{
                      "&.MuiSwitch-root .Mui-checked": {
                        color: Colors.Blue7,
                      },
                    }}
                  />
                </>
              ) : (
                <div className={formik?.values.jobStatus}>
                  <Text fontWeight={400} fontSize={14}>
                    {formik?.values.jobStatus}
                  </Text>
                </div>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.JOB_LOCATION}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {formik?.values.city +
                " " +
                formik?.values.stateCode +
                ", " +
                formik?.values.countryCode}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.JOB_POSTED}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {formik?.values.jobPostedDate}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          pl: 4,
          pt: 0,
        }}
      >
        <Tabsection formik={formik} appjobid={jobData.appjobid} />
      </Box>
    </Box>
  );
};

export default EditJob;
