import axios from "axios";
import ENDPOINTS from "./ENDPOINTS";
import STRINGS from "../utils/Strings";
import Functions from "../utils/Functions";

const login = async (email_Id: string, password: string) => {
  let data = JSON.stringify({
    emailId: email_Id,
    password: password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ACCOUNTS.LOGIN,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw errorHandler(error.response?.data);
    });
};

const linkValidation = (emailId: any, expiryTime: any, isReminder: any) => {
  let url = ENDPOINTS.ACCOUNTS.CHECK_CREATEPASSWORD_LINKVALIDATION;

  let params = "";

  params =
    "?emailId=" +
    emailId +
    "&expiryTime=" +
    expiryTime +
    "&isReminder=" +
    isReminder;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url + params,
    headers: {
      accept: "application/json",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const emailValidation = async (EmailId: string) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ACCOUNTS.EMAIL_VALIDATION + "?EmailId=" + EmailId,
    headers: {
      accept: "application/json",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw errorHandler(error.response?.data);
    });
};

const validateForgotPasswordLink = async (Key: string | null, Type: string) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.ACCOUNTS.VALIDATE_FORGOTPASSWORD_LINK +
      "?Key=" +
      Key +
      "&Type=" +
      Type,
    headers: {
      accept: "application/json",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response?.data;
    });
};

const resetPassword = async (
  forgotPwdTokenKey: string | null,
  password: string,
  confirmPassword: string
) => {
  const payload = {
    forgotPwdTokenKey: forgotPwdTokenKey,
    password: password,
    confirmPassword: confirmPassword,
  };

  let data = JSON.stringify(payload);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ACCOUNTS.RESETPASSWORD,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response?.data;
    });
};

const createPasswordLinkValidation = async (
  IsReminder: string | null,
  Key: string | null,
  Type: string | null
) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ACCOUNTS.CHECK_CREATEPASSWORD_LINKVALIDATION,
    headers: {
      accept: "application/json",
    },
    params: {
      IsReminder: IsReminder,
      Key: Key,
      Type: Type,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response?.data;
    });
};

const AccessToken = Functions.LocalStorage.getString(
  Functions.LocalStorage.KEYS.JWT_TOKEN
);

//Propose Interview Slots
const proposeInterviewSlots = async (payload: any) => {
  const token = localStorage.getItem("JWT_TOKEN");
  if (!token) {
    throw new Error(STRINGS.PROPOSE_TIME_SLOTS.AUTH_ERROR);
  }

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.PROPOSE_TIME_SLOTS.INTERVIEW_SLOTS,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    data: JSON.stringify(payload),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      if (error.response?.status === 401) {
        throw new Error(STRINGS.PROPOSE_TIME_SLOTS.UNAUTHORIZED_ERROR);
      }
      throw errorHandler(error.response?.data);
    });
};

const modifyInterviewSlots = async (payload: any) => {
  const token = localStorage.getItem("JWT_TOKEN");
  if (!token) {
    throw new Error(STRINGS.PROPOSE_TIME_SLOTS.AUTH_ERROR);
  }

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.PROPOSE_TIME_SLOTS.INTERVIEW_SLOTS,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    data: JSON.stringify(payload),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      if (error.response?.status === 401) {
        throw new Error(STRINGS.PROPOSE_TIME_SLOTS.UNAUTHORIZED_ERROR);
      }
      throw errorHandler(error.response?.data);
    });
};

const EnhanceJD = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.EXIMIUS.JOB_DETAILS,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",

      // "Content-Type": "application/json",
    },
    // data: JSON.stringify(payload),
    data: payload,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw errorHandler(error.response?.data);
    });
};

//Propose Interview Slots
const CREATEJOB = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let paydata: any = {
    clientId: "string",
    jobDetails: {
      jobTitle: payload.jobTitle,
      jobDescription: payload.jobDescription,
      numberOfOpenings: payload.numberOfOpenings,
      state: payload.state,
      city: payload.city,
      stateId: payload.stateId,
      countryName: payload.countryName,
      countryId: payload.countryId,
      countryCode:payload.countryCode,
      stateCode:payload.stateCode,

      cityId:payload.cityId,
      zipCode: payload.zipCode,
      requiredSkills: payload.skillSetarr,
    },
    jobTypeAndRate: {
      jobType: payload.jobType,
    },
    interviewMode: payload.interviewtype,
    modeofWork: payload.modeofwork,
    publishJob: true,
  };

  if (payload.jobType == "C") {
    paydata.jobTypeAndRate.billRate = payload.billRate;
    paydata.jobTypeAndRate.billRateCurrencyType = "USD";
  } else if (payload.jobType == "F") {
    paydata.jobTypeAndRate.salaryRange = payload.salaryRange;
    paydata.jobTypeAndRate.salaryRangeCurrencyType = "USD";
  } else {
    paydata.jobTypeAndRate.billRate = payload.billRate;
    paydata.jobTypeAndRate.billRateCurrencyType = "USD";
    paydata.jobTypeAndRate.salaryRange = payload.salaryRange;
    paydata.jobTypeAndRate.salaryRangeCurrencyType = "USD";
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOB.CREATEJOB,

    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      // 'Content-Type': 'multipart/form-data'
      "Content-Type": "application/json",
    },
    data: JSON.stringify(paydata),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw errorHandler(error.response?.data);
    });
};

const UPDATEJOB = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let paydata: any = {
    clientId: "string",
    id: payload.jobid,
    jobStatus: payload.jobStatus,
    jobDetails: {
      jobTitle: payload.jobTitle,
      jobDescription: payload.jobDescription,
      numberOfOpenings: payload.numberOfOpenings,
      state: payload.stateName,
      city: payload.city,
      stateId: payload.stateId,
      countryName: payload.countryName,
      countryId: payload.countryId,
      zipCode: payload.zipCode,
      cityId: payload.cityId,
      requiredSkills: payload.skillarr,
      countryCode:payload.countryCode,
      stateCode:payload.stateCode,
    },
    jobTypeAndRate: {
      jobType: payload.jobType,
    },
    interviewMode: payload.interviewtype,
    modeofWork: payload.modeofwork,
    publishJob: true,
    ModifyJobOldNewValue: payload.ModifyJobOldNewValue,
  };

  if (payload.jobType == "C") {
    paydata.jobTypeAndRate.billRate = payload.billRate;
    paydata.jobTypeAndRate.billRateCurrencyType = "USD";
  } else if (payload.jobType == "F") {
    paydata.jobTypeAndRate.salaryRange = payload.salaryRange;
    paydata.jobTypeAndRate.salaryRangeCurrencyType = "USD";
  } else {
    paydata.jobTypeAndRate.billRate = payload.billRate;
    paydata.jobTypeAndRate.billRateCurrencyType = "USD";
    paydata.jobTypeAndRate.salaryRange = payload.salaryRange;
    paydata.jobTypeAndRate.salaryRangeCurrencyType = "USD";
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOB.UPDATEJOB,

    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      // 'Content-Type': 'multipart/form-data'
      "Content-Type": "application/json",
    },
    data: JSON.stringify(paydata),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw errorHandler(error.response?.data);
    });
};

const Zipcity = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.MASTERS.ZIPCITY + payload,
    headers: {
      Authorization: "Bearer " + token,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw errorHandler(error.response?.data);
    });
};
const Citydata = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
  ? localStorage.getItem("JWT_TOKEN")
  : "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.MASTERS.CITYDATA+
      payload,
    headers: {
      Authorization: "Bearer " + token,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw errorHandler(error.response?.data);
    });
};
const Cityauto = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
  ? localStorage.getItem("JWT_TOKEN")
  : "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.MASTERS.CITYAUTO+"?searchString="+
      payload,
    headers: {
      Authorization: "Bearer " + token,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw errorHandler(error.response?.data);
    });
};
const getJobByID = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOB.GETJOBBYID + "/" + payload,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw errorHandler(error.response?.data);
    });
};

const getFilters = async (
  nameSearch?: string,
  locationSearch?: string,
  statusSearch?: string,
  jobSearch?: string,
  experienceSearch?: string
) => {
  let params = {};

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.APPLICATIONS.APPLICATION_FILTER_DATA,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + AccessToken,
    },
    params: {
      NameSearch: nameSearch,
      LocationSearch: locationSearch,
      StatusSearch: statusSearch,
      JobSearch: jobSearch,
      ExperienceSearch: experienceSearch,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getApplications = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.APPLICATIONS.APPLICATIONS,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + AccessToken,
    },
    // params: {
    //   IntStart: "",
    //   IntPageSize: "",
    //   IntSortColumn: "",
    //   SortDirection: "",
    //   JobId: "",
    //   Name: "",
    //   Location: "",
    //   Status: "",
    //   Experience: "",
    //   LoggedInUserId: "",
    //   NameIds: "",
    //   LocationIds: "",
    //   StatusIds: "",
    //   JobIds: "",
    //   Experiences: "",
    //   Bookmarked: "",
    // },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getApplicationById = async (Appid: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.APPLICATIONS.GETAPPLICATION + "?id=" + Appid,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getApplicationsByjobid = async (payload: any) => {
  let token = localStorage.getItem("JWT_TOKEN")
    ? localStorage.getItem("JWT_TOKEN")
    : "";
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOBS.JOB_APPLICATION,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      // 'Content-Type': 'multipart/form-data'
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getJobs = async (payload: any) => {
  let data = JSON.stringify({
    pageIndex: payload.pageIndex,
    pageSize: payload.pageSize,
    sortColumn: payload.sortColumn,
    sortOrder: payload.sortOrder,
    isSorted: payload.isSorted,
    advanceSearch: payload.advanceSearch,
    showActiveJobs: payload.showActiveJobs,
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOBS.GETJOB,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getJobFilters = async (
  JobTitleSearch?: string,
  JobLocationSearch?: string,
  EmploymentTypeSearch?: string,
  JobStatusSearch?: string,
  HiringManagerSearch?: string,
  NoOfApplicationSearch?: string,
  JobPostedOnSearch?: string
) => {
  const params = {
    JobTitleSearch: JobTitleSearch,
    JobLocationSearch: JobLocationSearch,
    EmploymentTypeSearch: EmploymentTypeSearch,
    JobStatusSearch: JobStatusSearch,
    HiringManagerSearch: HiringManagerSearch,
    NoOfApplicationSearch: NoOfApplicationSearch,
    JobPostedOnSearch: JobPostedOnSearch,
  };

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOBS.JOB_FILTER_DATA,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    params: params,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const updateJobStatus = async (jobId: any, jobStatus: string) => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.JOBS.JOBS +
      jobId +
      ENDPOINTS.JOBS.UPDATE_JOB_STATUS_POSTFIX +
      "?jobStatus=" +
      jobStatus,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getEnhaceJDattempt = async () => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.EXIMIUS.GET_COUNT,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const resetEnhaceJDattempt = async () => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: ENDPOINTS.EXIMIUS.RESET_COUNT,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getApplicationsNew = async (payload: any) => {
  let data = JSON.stringify({
    name: payload.name,
    location: payload.location,
    statuses: payload.statuses,
    jobs: payload.jobs,
    jobTitles: payload.jobTitle,
    experiences: payload.experiences,
    isBookmarked: payload.isBookmarked,
    pageIndex: payload.pageIndex,
    pageSize: payload.pageSize,
    sortColumn: payload.sortColumn,
    sortOrder: payload.sortOrder,
    isSorted: true,
    showActiveJobs: payload.showActiveJobs,

    recruiterGuid: "",
    jobId: "",
    searchKeywords: "",
    contractType: "",
    showPendingSubmissions: false,
    recruiterFilter: "",
    statusIds: [],
    durationFilter: {},
    recruiterGuids: [],
    candidateGuids: [],
    clientNames: [],
    clientGuid: "",
    totalRecord: 0,
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.GET_SUBMISSIONS,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

// url: "?NameSearch=1&LocationSearch=1&StatusSearch=1&JobSearch=1&ExperienceSearch=1",
const getFiltersForApplications = (payload: any) => {
  let params = {
    NameSearch: payload.NameSearch,
    LocationSearch: payload.LocationSearch,
    StatusSearch: payload.StatusSearch,
    JobSearch: payload.JobSearch,
    ExperienceSearch: payload.ExperienceSearch,
  };

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.SUBMISSIONS_FILTER_DATA,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    params: params,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const shareApplicationEmail = async (payload: any) => {
  let data = JSON.stringify({
    jobId: payload.jobId,
    jobTitle: payload.jobTitle,
    jobDescription: payload.jobDescription,
    emailIds: payload.emailIds,
    replyTo: payload.replyTo,
    candidateDocId: payload.candidateDocId,
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.SHARE_APPLICATION,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw error;
    });
};

const bookmarkSubmissions = async (submissionId: string) => {
  let data = JSON.stringify({
    submissionIds: [submissionId],
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.BOOKMARK_SUBMISSIONS,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
    });
};

const debookmarkSubmissions = async (submissionId: string) => {
  let data = JSON.stringify({
    submissionIds: [submissionId],
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.DE_BOOKMARK_SUBMISSIONS,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
    });
};

const hireCandidate = async (payload: any) => {
  let data = JSON.stringify({
    submissionId: payload.submissionId,
    comment: payload.comment,
    projectStartDate: payload.projectStartDate,
    isCloseJob: payload.isCloseJob,
    noOfPosition: payload.noOfPosition,
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.HIRE,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw error;
    });
};

const rejectCandidate = async (payload: any) => {
  let data = JSON.stringify({
    submissionId: payload.submissionId,
    comment: payload.comment,
    adminGuid: payload.adminGuid,
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.REJECT,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const downloadResume = async (candidateDocId: number) => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.RESUME,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    params: {
      candidateDocId: candidateDocId,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      throw error;
    });
};

const getJobByStafflineId = async (id: string) => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOBS.JOB_BY_STAFFLINE_ID + id,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getInterviewAvailabilitiesById = async (id: any) => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.INTERVIEW_DETAILS + id,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getJiraView = async (payload: any, isJobFilter: boolean) => {
  let data = JSON.stringify({
    submissionFilterInput: {
      name: payload.name,
      location: payload.location,
      statuses: payload.statuses,
      jobs: payload.jobs,
      jobTitles: payload.jobTitle,
      experiences: payload.experiences,
      isBookmarked: payload.isBookmarked,
      pageIndex: payload.pageIndex,
      pageSize: payload.pageSize,
      sortColumn: payload.sortColumn,
      sortOrder: payload.sortOrder,
      isSorted: true,
      showActiveJobs: payload.showActiveJobs,

      recruiterGuid: "",
      jobId: "",
      searchKeywords: "",
      contractType: "",
      showPendingSubmissions: false,
      recruiterFilter: "",
      statusIds: [],
      durationFilter: {},
      recruiterGuids: [],
      candidateGuids: [],
      clientNames: [],
      clientGuid: "",
      totalRecord: 0,
    },
    jobsFilterInput: {
      pageIndex: payload.pageIndex,
      pageSize: payload.pageSize,
      sortColumn: payload.sortColumn,
      sortOrder: payload.sortOrder,
      isSorted: payload.isSorted,
      advanceSearch: payload.advanceSearch,
      showActiveJobs: payload.showActiveJobs,
    },
    isJobFilter: isJobFilter,
  });

  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.JOBSUBMISSIONS_FOR_JIRAVIEW,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json-patch+json",
      Authorization: "Bearer " + JWT_TOKEN,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getRecentApplications = async () => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CANDIDATE_SUBMISSION.RECENT_SUBMISSIONS,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const getRecentJobs = async () => {
  const JWT_TOKEN = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.JOBS.RECENT_JOBS,
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + JWT_TOKEN,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkForExpiry(error.response?.data);
      console.log(error);
    });
};

const API = {
  // OLD
  login,
  linkValidation,
  emailValidation,
  validateForgotPasswordLink,
  resetPassword,
  createPasswordLinkValidation,
  proposeInterviewSlots,
  modifyInterviewSlots,
  EnhanceJD,
  resetEnhaceJDattempt,
  getEnhaceJDattempt,
  Zipcity,
  Cityauto,
  Citydata,
  CREATEJOB,
  UPDATEJOB,
  getJobByID,
  getFilters,
  getApplications,
  getApplicationById,
  getApplicationsByjobid,

  // NEW
  getJobs,
  getJobFilters,
  updateJobStatus,
  getApplicationsNew,
  getFiltersForApplications,
  shareApplicationEmail,
  bookmarkSubmissions,
  debookmarkSubmissions,
  hireCandidate,
  rejectCandidate,
  downloadResume,
  getJobByStafflineId,
  getInterviewAvailabilitiesById,
  getJiraView,
  getRecentApplications,
  getRecentJobs,
};

export default API;

const errorHandler = (error: any) => {
  if (error.isError) {
    return Object.values(error.errors?.errorList)[0];
  } else {
    return error.message;
  }
};

const checkForExpiry = (errorResponse: any) => {
  if (errorResponse.statusCode === 401) {
    Functions.LocalStorage.storeString(
      Functions.LocalStorage.KEYS.JWT_TOKEN,
      ""
    );
    window.location.reload();
  }
};
