// React Imports
import { FC } from "react";

// UI Imports
import { Paper, SxProps } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { AiOutlineDollar } from "react-icons/ai";
import { RiFlag2Line } from "react-icons/ri";

// Functional Imports
import { useNavigate } from "react-router-dom";
import ENUMS from "../../utils/Enums";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionTile from "../../common/HoverActionTile";

interface HoverActionProjectsProps {
  id?: string;
  status?: string;
  onClickActiveInactive?: any;
  sx?: SxProps;
}

const HoverActionProjects: FC<HoverActionProjectsProps> = (props) => {  return (
    <Paper
      data-testid="hover-actions-jobs-popup"
      sx={{
        backgroundColor: Colors.White,
        width: 200,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <HoverActionTile
        testId="view-details-btn"
        icon={<GiHamburgerMenu size={20} color={Colors.Blue7} />}
        title="View details"
      />
      <HoverActionTile
        testId="change-rate-btn"
        icon={<AiOutlineDollar size={20} color={Colors.Blue7} />}
        title="Change rate"
      />
      <HoverActionTile
        testId="end-project-btn"
        icon={<RiFlag2Line size={20} color={Colors.Blue7} />}
        title="End project"
      />
    </Paper>
  );
};

export default HoverActionProjects;
