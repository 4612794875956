import React, { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";
import "./CreateJob.css";

// Functional Imports
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";

// Component Imports
import Breadcrumbs from "../../common/Breadcrumbs";
import ButtonOutlined from "../../common/ButtonOutlined";

import Staper from "../../common/Staper";
import JobDescription from "./JobDescription";
import Publishjob from "./PublishJob";
import CancelJob from "./CancelJob";
import { useFormik } from "formik";
import Text from "../../common/Text";
import STRINGS from "../../utils/Strings";
import * as Yup from "yup";
import Loader from "../../common/Loader";
import API from "../../api/API";
import Toasts from "../../utils/Toasts";
import { useNavigate } from "react-router-dom";

interface CreateJobProps {
  isTesting?: boolean;
}

const CreateJob: FC<CreateJobProps> = (props) => {
  const [currentStap, setCurrentstap] = useState(0);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      jobTitle: "",
      modeofwork: "",
      skillSetarr: [],
      skillSet: "",
      enhanceJd:"",
      jobDescription: "",
      numberOfOpenings: "1",
      jobType: "",
      billRate: "",
      salaryRange: "",
      zipCode: "",
      stateName: "",
      city: "",
      countryName: "",
      interviewtype: "",
      countryId: "",
      stateId: "",
      cityId:"",
      atemptLeft:"",
      stateCode: "",
      countryCode: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      jobTitle: Yup.string()
        .max(100, STRINGS.CREATE_JOB.TITLE_LENGTH_ERROR_MSG)
        .required(STRINGS.CREATE_JOB.FILL_REQ_FIELD_TEXT),
      city: Yup.string().required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
      numberOfOpenings: Yup.number()
      .positive('It must be greater than zero')
      .required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      jobType: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      zipCode: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      billRate: Yup.string().when("jobType", {
        is: (jobType: any) => jobType === "C" || jobType === "R",
        then: (schema) =>
          schema.required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
      }),
      salaryRange: Yup.string().when("jobType", {
        is: (jobType: any) => jobType === "F" || jobType === "R",
        then: (schema) =>
          schema.required(STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG),
      }),
      interviewtype: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      stateName: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
      countryName: Yup.string().required(
        STRINGS.CREATE_JOB.TITLE_REQUIRED_ERROR_MSG
      ),
    }),

    onSubmit: (values) => {
      
      setLoading(true);

      API.CREATEJOB(values)
        .then((response) => {
          if (response?.status === 200) {
            Toasts.success(
              "Job " + response?.entity?.id + " has been created successfully!"
            );
            API.resetEnhaceJDattempt()
            .then((response) => {
            })
            .catch((e) => {
              Toasts.error(e);
            });
              setLoading(false);
              navigate(Paths.JOBS)
          }
        })
        .catch((e) => {
          Toasts.error(e);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      handleCancel();
      handleNext();
      handlePrev();
      getStapsItem(0);
      getStapsItem(1);
      getStapsItem(2);
      formik.setValues(testSample).then(() => {
        formik.handleSubmit();
      });
    }
  }, []);

  const handleCancel = () => {
    setCancelPopup(true);
  };


  const handleNext = () => {
    setCurrentstap(currentStap + 1);
  };


  const handlePrev = () => {
    setCurrentstap(currentStap - 1);
  };

  const getStapsItem = (steps: number) => {
    switch (steps) {
      case 0:
        return (
          <JobDescription
            formik={formik}
            handleNext={handleNext}
            data-testid="job-desc-comp"
          />
        );
      case 1:
        return (
          <Publishjob
            formik={formik}
            handlePrev={handlePrev}
            data-testid="publish-job-component"
          />
        );
      default:
        return <JobDescription formik={formik} handleNext={handleNext} />;
    }
  };
  return (
    <Box
      data-testid="Create-job-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {loading && <Loader />}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.SLASH, name: "Home" },
            { path: Paths.JOBS, name: "Jobs" },
            { name: STRINGS.CREATE_JOB.CREATE_JOB_HEADING },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 4,
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          position: "relative",
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          {STRINGS.CREATE_JOB.CREATE_JOB_HEADING}
        </Text>
        <ButtonOutlined
          testId="Cancel"
          onClick={handleCancel}
          title={STRINGS.CREATE_JOB.CANCEL}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 4,
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: Colors.White,
            textAlign: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <Staper
              sx={{
                pt: 5,
                width: "40%",
              }}
              activeStap={currentStap}
              label={[
                STRINGS.CREATE_JOB.JOD_DESCRIPTION,
                STRINGS.CREATE_JOB.PUBLISH_JOB,
              ]}
            />
          </Box>
          {getStapsItem(currentStap)}
        </Box>
      </Box>
      <CancelJob cancelopen={cancelPopup} setCancelopen={setCancelPopup} />
    </Box>
  );
};

export default CreateJob;

const testSample: any = {
  jobTitle: "w",
  modeofwork: "remote",
  skillSetarr: ["er"],
  skillSet: "er",
  jobDescription: "<p>d</p>",
  numberOfOpenings: "1",
  jobType: "R",
  billRate: "3",
  salaryRange: "34",
  zipCode: "ABC",
  stateName: "ABC",
  city: "Coorg and Nearby",
  countryName: "ABC",
  interviewtype: "2",
  countryCode: "US",
  stateCode: "NY",
};
