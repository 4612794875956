// React Imports
import { FC, ReactNode } from "react";

// UI Imports
import { Box, IconButton, SxProps } from "@mui/material";
import { HiDotsHorizontal } from "react-icons/hi";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";
import ActionTileDashboard from "./ActionTileDashboard";

interface DashboardTileRowProps {
  sx?: SxProps;
  icon?: ReactNode;
  topTitle?: string;
  bottomTitle?: string;
  handleClick?: any;
  id?: string;
  hoverType?: string;
}

const DashboardTileRow: FC<DashboardTileRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        my: 1,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            height: 40,
            width: 40,
            borderRadius: 20,
            border: "1px solid " + Colors.Grey16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.icon}
        </Box>
        <Box>
          <Text fontWeight={700}>{props.topTitle}</Text>
          <Text fontSize={10}>{props.bottomTitle}</Text>
        </Box>
      </Box>
      <ActionTileDashboard
        id={props.id}
        handleClick={props.handleClick}
      />
    </Box>
  );
};

export default DashboardTileRow;
