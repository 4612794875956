import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  IconButton,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar, PickersDayProps } from "@mui/x-date-pickers";
import { ArrowForward as ArrowForwardIcon, Delete } from "@mui/icons-material";
import CustomCalendarHeader from "./CustomCalendarHeader";
import CustomDay from "./CustomDay";
import STRINGS from "../../utils/Strings";
import API from "../../api/API";
import {
  handleTimeChange,
  calculateTimeDifference,
} from "../../utils/timeUtils";
import { v4 as uuidv4 } from "uuid";
import ConfirmationDialog from "./ConfirmationDialog";
import Toasts from "../../utils/Toasts";

const HOURS_IN_A_DAY = 24;

interface ProposeTimeSlotsProps {
  onCancel: () => void;
  submissionID: any;
  candidateName?: any;
  candidateEmail?: any;
  initialSlots?: any[];
  modifyMode?: boolean;
  slotId?: any;
  isTesting?: boolean;
  description?: string;
  onRefresh?: any;
  initialSlotForProps?: any;
  isFromInterviewDetail?: any;
  setDescription?: any;
  resumeVLNStatus?: any;
}

type Slot = {
  id: string;
  date: string;
  time: string;
  borderColor?: string;
  fontColor?: string;
} | null;

const createCustomDayComponent = (
  selectedDates: Set<string>,
  currentDate: dayjs.Dayjs
) => {
  return function CustomDayComponent(props: PickersDayProps<dayjs.Dayjs>) {
    return (
      <CustomDay
        {...props}
        selectedDates={selectedDates}
        currentDate={currentDate}
      />
    );
  };
};

const ProposeTimeSlots: FC<ProposeTimeSlotsProps> = ({
  onCancel,
  submissionID,
  slotId,
  description,
  candidateName,
  candidateEmail,
  initialSlots,
  modifyMode,
  isTesting,
  onRefresh,
  initialSlotForProps,
  isFromInterviewDetail,
  setDescription,
  resumeVLNStatus
}) => {
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(today);
  const [fromTime, setFromTime] = useState<string>("");
  const [toTime, setToTime] = useState<string>("");
  const [timeDifference, setTimeDifference] = useState<string>("1h");
  const [selectedSlot, setSelectedSlot] = useState<number | null>(null);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [slots, setSlots] = useState<(Slot | null)[]>(initialSlotForProps);
  const [selectedDates, setSelectedDates] = useState<any>(new Set());
  const [name] = useState(candidateName);
  const [email, setEmail] = useState(candidateEmail);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [notes, setNotes] = useState("");
  const [deleteSlotId, setDeleteSlotId] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [initialSlotsState, setInitialSlotsState] = useState<Slot[]>([]);
  const [initialNotesState, setInitialNotesState] = useState<string>("");
  const [isAllPastDates, setIsAllPastDates] = useState(false);
  const [isFreshProposal, setIsFreshProposal] = useState(false);
  const [isModification, setIsModification] = useState(false);

  const mockEvent = {
    target: { value: 'This is a sample note input.' }
  } as React.ChangeEvent<HTMLInputElement>;

  useEffect(() => {
    if (isTesting) {
      setFromTime("09:00");
      setToTime("10:00");
      setSelectedDate(dayjs());
      setNotes("Test notes");
      handleAddTimeSlot();
      handleOpenDialog("123");
      handleCloseDialog();
      handleSlotChange(1, "abc");
      handleConfirmDelete();
      handleSubmit();
      fillInitialSlots();
      handleNotesChange(mockEvent);
      handleArrowIconClick();
      formatSlot('1234');
    }
  }, []);

  useEffect(() => {
    fillInitialSlots();
  }, [initialSlots]);

  useEffect(() => {
    if (description && modifyMode) {
      setNotes(description);
    }
  }, [description, modifyMode]);

  useEffect(() => {
    setTimeDifference(calculateTimeDifference(fromTime, toTime));
  }, [fromTime, toTime]);

  useEffect(() => {
    if (modifyMode && initialSlots) {
      const formattedInitialSlots = initialSlots.map((slot) =>
        slot
          ? {
            id: slot.id,
            date: dayjs(slot.interviewDate).format(
              STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT
            ),
            time: dayjs(slot.interviewTime, "HH:mm").format(
              STRINGS.PROPOSE_TIME_SLOTS.TIME_FORMAT
            ),
            duration:
              slot.interviewTime && slot.endTime
                ? calculateTimeDifference(
                  dayjs(slot.interviewTime, "HH:mm").format("HH:mm"),
                  dayjs(slot.endTime, "HH:mm").format("HH:mm")
                )
                : "1h",
          }
          : null
      );

      setInitialSlotsState(formattedInitialSlots);
      setInitialNotesState(description || "");
      setIsSubmitDisabled(true);
    }
  }, [modifyMode, initialSlots, description]);

  const formatSlot = (slot: any) => {
    if (!slot) return null;

    const date = dayjs(slot.interviewDate).format(
      STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT
    );
    const time = dayjs(slot.interviewTime, "HH:mm").format(
      STRINGS.PROPOSE_TIME_SLOTS.TIME_FORMAT
    );
    const duration =
      slot.interviewTime && slot.endTime
        ? calculateTimeDifference(
          dayjs(slot.interviewTime, "HH:mm").format("HH:mm"),
          dayjs(slot.endTime, "HH:mm").format("HH:mm")
        )
        : "1h";
    const borderColor = slot.isFinalized ? "#4A57A3" : "#D1D1D1";
    const fontColor = slot.isFinalized ? "#4A57A3" : "#353535";

    return { id: slot.id, date, time, duration, borderColor, fontColor };
  };

  const handleAllPastDates = (slots: any) => {
    const validSlots = slots.filter((slot: any) => slot !== null)

    const allPastDates = validSlots.every((slot: any) =>
      dayjs(slot.date).isBefore(dayjs(), "day")
    );

    return allPastDates && validSlots.length === slots.length && slots.length === 3;
  };

  const fillInitialSlots = () => {
    if (!initialSlots || initialSlots.length === 0) {
      setSlots([null, null, null]);
      setIsFreshProposal(true);
      setIsModification(false);
      setIsAllPastDates(false);
      setSelectedDates(new Set());
      return;
    };

    const prefilledSlots = initialSlots
    .map((slot) => {
      if (slot) {
        const formattedSlot = formatSlot(slot);
        return formattedSlot ? { ...formattedSlot, duration: slot.interviewDuration || formattedSlot.duration } : null;
      }
      return null;
    })
    .filter((slot) => slot !== null) as Slot[];

    prefilledSlots.sort((a: any, b: any) => {
      const dateA = new Date(`${a.date} ${a.time}`).getTime();
      const dateB = new Date(`${b.date} ${b.time}`).getTime();
      return dateA - dateB;
    });

    const hasAllPastDates = handleAllPastDates(prefilledSlots)

    if (hasAllPastDates && !isFromInterviewDetail) {
      setSlots([null, null, null]);
      setSelectedDates(new Set());
      setIsAllPastDates(true);
      setIsFreshProposal(true);
      setIsModification(false);
      return;
    } else {
      setIsAllPastDates(false);
      setIsFreshProposal(false);
      setIsModification(true);
    }

    while (prefilledSlots.length < 3) {
      prefilledSlots.push(null);
    }

    setSlots(prefilledSlots);

    const prefilledDates = new Set(
      prefilledSlots.filter((slot) => slot !== null).map((slot) => slot?.date)
    );
    setSelectedDates(prefilledDates);
  };

  const handleAddTimeSlot = () => {
    if (!fromTime || !toTime || !timeDifference) {
      Toasts.error(
        "Both From Time and To Time fields are required and must have a valid duration"
      );
      return;
    }

    const selectedSlotDate = selectedDate?.format(
      STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT
    );

    const isDuplicateSlot = slots.some(
      (slot) => slot && slot.date === selectedSlotDate && slot.time === fromTime
    );

    if (isDuplicateSlot) {
      Toasts.error(
        "This time slot has already been added for the selected date."
      );
      return;
    }

    const duration = calculateTimeDifference(fromTime, toTime);

    if (
      selectedDate &&
      fromTime &&
      toTime &&
      duration &&
      slots.filter(Boolean).length < STRINGS.PROPOSE_TIME_SLOTS.MAX_SLOTS
    ) {
      const newSlot = {
        id: uuidv4(),
        date: selectedDate?.format(STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT),
        time: fromTime,
        duration,
      };
      const newSlots = slots.slice();
      const emptyIndex = newSlots.findIndex((slot) => slot === null);
      newSlots[emptyIndex] = newSlot;
      setSlots(newSlots);
      setSelectedDates((prev: any) => new Set(prev).add(newSlot.date));
      setFromTime("");
      setToTime("");
      setTimeDifference("1h");
    }
  };

  const handleArrowIconClick = () => {
    setOpenFrom((prev) => !prev);
    setOpenTo(false);
  };

  const handleOpenDialog = (id: string) => {
    setDeleteSlotId(id);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDeleteSlotId(null);
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (deleteSlotId || isTesting) {
      const updatedSlots = slots.map((slot) =>
        slot && slot.id === deleteSlotId ? null : slot
      );
      setSlots(updatedSlots);

      const deletedSlot = slots.find(
        (slot) => slot && slot.id === deleteSlotId
      );
      if (deletedSlot) {
        const slotDate = deletedSlot.date;

        const isLastSlotOnDate = !updatedSlots.some(
          (slot) => slot && slot.date === slotDate
        );

        if (isLastSlotOnDate) {
          setSelectedDates((prevDates: any) => {
            const newDates = new Set(prevDates);
            newDates.delete(slotDate);
            return newDates;
          });
        }
      }

      setDeleteSlotId(null);
      setIsDialogOpen(false);
    }
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let i = 0; i < HOURS_IN_A_DAY; i++) {
      const hour = i < 10 ? `0${i}` : i.toString();
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
    }
    return times;
  };

  const handleSlotChange = (index: number, slot: string) => {
    const [start, end] = slot.split(" - ");
    setFromTime(start);
    setToTime(end);
    setSelectedSlot(index);
    const diff = calculateTimeDifference(start, end);
    setTimeDifference(diff);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setNotes(value.slice(0, 1000));
    } else {
      setNotes(value);
    }
  };

  const isSlotAlreadyAdded = (slotTime: string) => {
    return slots.some(
      (slot) =>
        slot &&
        slot.time === slotTime &&
        slot.date ===
        selectedDate?.format(STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT)
    );
  };

  const generateHourlySlots = () => {
    const slotsArray = [];
    for (let i = 0; i < HOURS_IN_A_DAY; i++) {
      const startHour = i < 10 ? `0${i}:00` : `${i}:00`;
      const endHour = i < 9 ? `0${i + 1}:00` : `${i + 1}:00`;
      const time = `${startHour} - ${endHour}`;

      const isDisabled = isSlotAlreadyAdded(startHour);

      slotsArray.push({ id: i, time, isDisabled });
    }
    return slotsArray;
  };

  const timeSlots = generateHourlySlots();


  const handleSubmit = async () => {

    let isValid = true;
    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (isValid) {
      const slotsPayload = slots
        .filter(
          (
            slot
          ): slot is {
            id: string;
            date: string;
            time: string;
            duration: string;
          } => slot !== null
        )
        .map((slot) => ({
          interviewDate: slot.date,
          interviewTime: slot.time,
          interviewDuration: slot.duration,
        }));

      const payload = {
        submissionId: submissionID,
        availabilities: slotsPayload,
        description: notes,
      };

      const modifyPayload = {
        id: slotId,
        submissionId: submissionID,
        availabilities: slotsPayload,
        description: notes,
        isSlotChange:
          JSON.stringify(normalizeSlots(initialSlotsState)) !==
          JSON.stringify(normalizeSlots(slots)),
      };


      try {
        if (isFreshProposal) {
          await API.proposeInterviewSlots(payload);
          Toasts.success("Interview slot has been proposed successfully!");
        } else if (isModification) {
          await API.modifyInterviewSlots(modifyPayload);
          Toasts.success("Interview slot has been modified successfully!");
        } else if (isAllPastDates) {
          await API.proposeInterviewSlots(payload);
          Toasts.success("Interview slot has been proposed successfully due to past-dated slots!");
        }
        onCancel();
        if (onRefresh) onRefresh();
      } catch (error) {
        Toasts.error("Failed to submit interview slot");
      }
    }
  };

  const CustomDayComponent = createCustomDayComponent(selectedDates, today);

  const normalizeSlots = (slots: Slot[]): string[] => {
    return slots
      .filter((slot) => slot !== null)
      .map((slot) => `${slot!.id}-${slot!.date}-${slot!.time}`)
      .sort();
  };

  useEffect(() => {
    if (modifyMode) {
      const normalizedInitialSlots = normalizeSlots(initialSlotsState);
      const normalizedCurrentSlots = normalizeSlots(slots);

      const isSlotChange =
        JSON.stringify(normalizedInitialSlots) !==
        JSON.stringify(normalizedCurrentSlots);
      const notesChanged = initialNotesState !== notes;
      const allSlotsDeleted = normalizedCurrentSlots.length === 0;
      setIsSubmitDisabled(!(isSlotChange || notesChanged) || allSlotsDeleted);
    } else {
      setIsSubmitDisabled(slots.filter(Boolean).length === 0);
    }
  }, [slots, notes, modifyMode, initialSlotsState, initialNotesState]);

  const isAddButtonDisabled = () => {
    if (!fromTime || !toTime || resumeVLNStatus === "Hired" || resumeVLNStatus === "Rejected") {
      return true;
    }

    if (slots.filter(Boolean).length >= STRINGS.PROPOSE_TIME_SLOTS.MAX_SLOTS) {
      return true;
    }

    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          width: "948px",
          height: "718px",
          borderRadius: "8px",
          position: "relative",
          backgroundColor: "white",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            gap: "8px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              height: "24px",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              paddingBottom: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "24px",
                textAlign: "left",
                color: "#353535",
              }}
            >
              Propose interview slot
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: { xs: "auto", md: "428px" },
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "428px",
                padding: "0px 0px 24px 0px",
                gap: "20px",
                borderRadius: "8px",
                border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "350px",
                  height: "42px",
                  padding: "16px 32px",
                  gap: "81px",
                  borderBottom: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "235px",
                    height: "19px",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "18.75px",
                    textAlign: "left",
                    color: "#353535",
                  }}
                >
                  Select Dates
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "336px",
                  height: "332px",
                  marginTop: "20px",
                }}
              >
                <DateCalendar
                  slots={{
                    calendarHeader: CustomCalendarHeader,
                    day: CustomDayComponent,
                  }}
                  onChange={(date) => setSelectedDate(date)}
                  value={selectedDate}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                width: { xs: "100%", md: "552px" },
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ width: "100%" }}
              >
                <TextField
                  data-testid="selectedDate"
                  value={
                    selectedDate
                      ? selectedDate.format(
                        STRINGS.PROPOSE_TIME_SLOTS.INPUT_DATE_FORMAT
                      )
                      : ""
                  }
                  sx={{
                    width: "131px",
                    backgroundColor:
                      STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                    borderRadius: "6px",
                    border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                  }}
                  inputProps={{
                    readOnly: true,
                    style: {
                      width: "107px",
                      height: "2px",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16.41px",
                      textAlign: "left",
                      color: "#353535",
                    },
                  }}
                />
                <Box
                  sx={{
                    width: "262px",
                    height: "36px",
                    gap: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "6px 0px 0px 0px",
                  }}
                >
                  <Autocomplete
                    freeSolo
                    open={openFrom}
                    onOpen={() => setOpenFrom(true)}
                    onClose={() => setOpenFrom(false)}
                    value={fromTime}
                    onInputChange={(e, newValue) => {
                      handleTimeChange(
                        setFromTime,
                        newValue,
                        toTime,
                        false,
                        setTimeDifference
                      );
                    }}
                    options={generateTimeOptions()}
                    clearOnEscape
                    disableClearable={!fromTime}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-testid="fromTime"
                        sx={{
                          height: "36px",
                          width: "105px",
                          borderRadius: "6px",
                          border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                          backgroundColor:
                            STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                          "& .MuiInputBase-root": {
                            height: "36px",
                          },
                          "& .MuiInputBase-input": {
                            boxSizing: "border-box",
                            height: "36px",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 500,
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "36px",
                          },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          title: "Enter a valid time in HH:mm format",
                          maxLength: 5,
                          inputMode: "numeric",
                          pattern: "\\d{2}:\\d{2}",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9:]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                  <Box
                    data-testid="arrow-icon-btn"
                    sx={{
                      width: "20px",
                      height: "20px",
                      padding: "3px 2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#8D8D8D",
                    }}
                    onClick={handleArrowIconClick}
                  >
                    <ArrowForwardIcon />
                  </Box>
                  <Autocomplete
                    freeSolo
                    open={openTo}
                    onOpen={() => setOpenTo(true)}
                    onClose={() => setOpenTo(false)}
                    value={toTime}
                    clearOnEscape
                    disableClearable={!toTime}
                    onInputChange={(e, newValue) => {
                      handleTimeChange(
                        setToTime,
                        newValue,
                        fromTime,
                        false,
                        setTimeDifference
                      );
                    }}
                    options={generateTimeOptions()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-testid="toTime"
                        sx={{
                          width: "105px",
                          borderRadius: "6px",
                          height: "36px",
                          backgroundColor:
                            STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                          border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                          "& .MuiInputBase-root": {
                            height: "36px",
                          },
                          "& .MuiInputBase-input": {
                            height: "36px",
                            fontSize: "14px",
                            boxSizing: "border-box",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "36px",
                          },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          title: "Enter a valid time in HH:mm format",
                          maxLength: 5,
                          inputMode: "numeric",
                          pattern: "\\d{2}:\\d{2}",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9:]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" gap={2}>
                  <Typography
                    data-testid="timeDifference"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "16.41px",
                      color: "#353535",
                      width: "40px",
                      marginTop: "10px",
                    }}
                  >
                    {timeDifference}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleAddTimeSlot}
                    sx={{
                      width: "82px",
                      height: "36px",
                      border: "1px solid #4A57A3",
                      color: "#4A57A3",
                      "&.Mui-disabled": {
                        backgroundColor: "#A1A1A1 !important",
                        color: "#FFF !important",
                      },
                      textTransform: "none",
                    }}
                    disabled={isAddButtonDisabled()}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "552px",
                  height: "314px",
                  gap: "16px",
                  borderRadius: "8px",
                  border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    width: "520px",
                    height: "296.89px",
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    "&::-webkit-scrollbar": {
                      width: "10px",
                      borderRadius: "10px",
                      border: "2px solid #f5f5f5",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#e0e0e0",
                      borderRadius: "10px",
                    },
                  }}
                >
                  {timeSlots.map((slot) => (
                    <Box
                      key={slot.id}
                      data-testid={`slot-${slot.id}`}
                      sx={{
                        width: "520px",
                        height: "54px",
                        borderRadius: "8px",
                        border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR}`,
                        backgroundColor: slot.isDisabled
                          ? "#d3d3d3"
                          : "#f5f5f5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        pointerEvents: slot.isDisabled ? "none" : "auto",
                        opacity: slot.isDisabled ? 0.5 : 1,
                      }}
                    >
                      <Typography
                        sx={{
                          width: "435px",
                          height: "54px",
                          paddingTop: "17px",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          fontWeight: 700,
                          lineHeight: "22px",
                          textAlign: "left",
                        }}
                      >
                        {slot.time}
                      </Typography>
                      <Radio
                        data-testid={`radio-button-${slot.id}`}
                        checked={selectedSlot === slot.id}
                        onChange={() => handleSlotChange(slot.id, slot.time)}
                        disabled={slot.isDisabled}
                        sx={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          "&.Mui-checked": {
                            color:
                              STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR,
                          },
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "18px",
                }}
              >
                {slots.map((slot, index) => {
                  const isPastDate = slot
                    ? dayjs(slot.date).isBefore(dayjs(), "day")
                    : false;
                  return (
                    <Box
                      key={slot?.id || `placeholder-${index}`}
                      sx={{
                        width: "172px",
                        height: "46px",
                        border: `1px solid ${slot?.borderColor || "#D1D1D1"}`,
                        background: slot
                          ? `#F8F8F8`
                          : `repeating-linear-gradient(
                  -45deg, 
                  ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_COLOR}, 
                  ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_COLOR} 3px, 
                  ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_STRIPE_COLOR} 3px, 
                  ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_STRIPE_COLOR} 5px
              )`,
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: isPastDate || resumeVLNStatus === "Hired" || resumeVLNStatus === "Rejected" ? 0.5 : 1,
                        pointerEvents: isPastDate ? "none" : "auto",

                      }}
                    >
                      {slot ? (
                        <Box
                          sx={{
                            width: "155px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "15.23px",
                              textAlign: "left",
                              color: `${slot?.fontColor}`,
                              marginLeft: "2px",
                            }}
                          >
                            {dayjs(slot.date).format("dddd, MMM DD")}
                            <Box>{slot.time}</Box>
                          </Typography>
                          <IconButton
                            data-testid={`delete-button-${index}`}
                            sx={{
                              width: "16px",
                              height: "18px",
                              color: isPastDate ? "#D1D1D1" : "#FA7070",
                            }}
                            disabled={isPastDate || resumeVLNStatus === "Hired" || resumeVLNStatus === "Rejected"}
                            onClick={() => handleOpenDialog(slot.id)}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      ) : (
                        " "
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: { xs: "auto", md: "176px" },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              width: "376px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                width: "376px",
                gap: "8px",
                height: "72px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <FormControl sx={{ width: "376px", gap: "5px" }}>
                <FormLabel
                  sx={{
                    height: "16px",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16.41px",
                    fontFamily: "Roboto",
                    textAlign: "left",
                    "&::after": {
                      color: STRINGS.PROPOSE_TIME_SLOTS.ERROR_COLOR,
                      content: '"*"',
                      marginLeft: "0.25em",
                    },
                  }}
                >
                  Name
                </FormLabel>
                <TextField
                  data-testid="name"
                  required
                  value={name}
                  sx={{
                    width: "376px",
                    height: "48px",
                    border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR} 1px`,
                    backgroundColor:
                      STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                  }}
                  inputProps={{
                    readOnly: true,
                    style: {
                      height: "15px",
                    },
                  }}
                />
                {nameError && (
                  <Typography
                    sx={{
                      color: STRINGS.PROPOSE_TIME_SLOTS.ERROR_COLOR,
                      fontSize: "12px",
                      mt: 1,
                    }}
                  >
                    {nameError}
                  </Typography>
                )}
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "376px",
                height: "72px",
                gap: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "476px", gap: "5px" }}>
                <FormLabel
                  sx={{
                    height: "16px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16.41px",
                    textAlign: "left",
                    "&::after": {
                      content: '"*"',
                      color: STRINGS.PROPOSE_TIME_SLOTS.ERROR_COLOR,
                      marginLeft: "0.25em",
                    },
                  }}
                >
                  Email ID
                </FormLabel>
                <TextField
                  data-testid="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    width: "376px",
                    height: "48px",
                    border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR} 1px`,
                    backgroundColor:
                      STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                  }}
                  inputProps={{
                    "data-testid": "email-input",
                    readOnly: true,
                    style: {
                      height: "15px",
                    },
                  }}
                />
                {emailError && (
                  <Typography
                    sx={{
                      color: STRINGS.PROPOSE_TIME_SLOTS.ERROR_COLOR,
                      fontSize: "12px",
                      mt: 1,
                    }}
                  >
                    {emailError}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              width: "476px",
              height: "auto",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FormControl sx={{ gap: "5px" }}>
              <FormLabel
                sx={{
                  height: "16px",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16.41px",
                  textAlign: "left",
                }}
              >
                Notes
              </FormLabel>
              <TextField
                data-testid="notes"
                multiline
                rows={6}
                value={notes}
                onChange={handleNotesChange}
                inputProps={{
                  "data-testid": "notes-input",
                }}
                sx={{
                  width: "476px",
                  height: "152px",
                  border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR} 1px`,
                  backgroundColor:
                    STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                }}
                InputProps={{
                  style: {
                    padding: "6px 10px",
                  },
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            width: "226px",
            height: "50px",
            gap: "16px",
            position: "relative",
            alignSelf: "flex-end",
            display: "flex",
          }}
        >
          <Button
            sx={{
              padding: "14px 24px",
              gap: "10px",
              borderRadius: "6px",
              border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR}`,
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "22px",
              textAlign: "left",
              color: STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR,
              textTransform: "none",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            data-testid="submit-button"
            sx={{
              padding: "14px 24px",
              gap: "10px",
              borderRadius: "6px",
              backgroundColor: STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR,
              color: "#FFFFFF",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "22px",
              textAlign: "left",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#6B74B8",
              },
              "&.Mui-disabled": {
                backgroundColor: "#A1A1A1 !important",
                color: "#fff !important",
              },
            }}
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
      />
    </LocalizationProvider>
  );
};

export default ProposeTimeSlots;
