// React Imports
import React, { FC, useEffect, useState } from "react";

// UI Imports
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  IoClose,
  IoInformationCircleOutline,
  IoDownloadOutline,
  IoCopyOutline,
} from "react-icons/io5";
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";
import ButtonFilled from "../../common/ButtonFilled/ButtonFilled";
import Text from "../../common/Text";
import QuillEditor from "../../common/QuillEditor";
import { BorderBottom } from "@mui/icons-material";

interface EnhanceJDProps {
  isjdExpanded: boolean;
  setIsjdExpanded?: any;
  formikJD?: any;
  jobDescription?: any;
  formik?: any;
  handleEnhanceJDClick?: any;
  handleEditorChange?: any;
  handleNext?: any;
  attemptsLeft?: any;
  loading?: any;
  isTesting?: boolean;
}

const EnhanceJD: FC<EnhanceJDProps> = (props) => {
  const [value, setValue] = useState();

  useEffect(() => {
    if (props.isTesting) {
      handleClose();
      handleNext();
    }
  }, []);

  useEffect(() => {
    setValue(props.formik.values.jobDescription)
  }, [props.isjdExpanded]);
  const handleClose = () => {
    props.setIsjdExpanded(false);
    props.formik?.setFieldValue("enhanceJd", "");
  };


  const handleNext = () => {
    props.formik?.setFieldValue(
      "jobDescription",
      props.formik?.values?.enhanceJd
    );
    props.handleNext();
    props.formik?.setFieldValue("enhanceJd", "");
  };

  const getTooltipText = () => {
    if (props.attemptsLeft === 0) {
      return "No attempts left";
    } else if (props.attemptsLeft === 1) {
      return "You can only enhance your JD once";
    } else if (props.attemptsLeft === 2) {
      return `You can only enhance your JD twice`;
    } else {
      return `You can only enhance your JD three times`;
    }
  };

  return (
    <>
      <Modal open={props.isjdExpanded} onClose={handleClose} className="enhancejdpopup">
        <Box
          data-testid="enhance-jd-modal"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 32px)",
            maxWidth: "1048px",
            height: "600px",
            padding: "24px 32px",
            gap: "24px",
            borderRadius: "4px",
            backgroundColor: Colors.White,
            boxShadow: 24,
            outline: "none",
          }}
        >
          <Box
            sx={{
              width: "984px",
              height: "28px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text fontWeight={700} fontSize={24}>
              Enhance JD
            </Text>
            <IconButton onClick={handleClose} data-testid="close-button">
              <IoClose />
            </IconButton>
          </Box>

          <Grid
            container
            sx={{
              width: "984px",
              height: "500px",
              gap: "8px",
              marginTop: "16px",
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "440px",
                  display: "flex",
                  gap: "16px",
                }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <QuillEditor
                      data-testid="editor"
                      editorStyle={{
                        height: 340,
                        BorderBottom:"none"
                      }}
                      label=""
                      value={value}
                      onChange={(ev: any) => {
                        setValue(ev);
                        props.formik?.setFieldValue("jobDescription", ev);
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "50px",
                        padding: "10px 5px 5px 0",
                        border: "1px solid #CCCCCC",
                        borderTop: "none",
                        marginTop: 0,
                      }}
                    >
                      {props.attemptsLeft < 4 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            title={getTooltipText()}
                            arrow
                            placement="bottom-start"
                          >
                            <IconButton sx={{ paddingRight: "0px" }}>
                              <IoInformationCircleOutline
                                style={{
                                  width: "16.33px",
                                  height: "16.33px",
                                  color: "#353535",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              fontWeight: 600,
                              color: "#353535",
                            }}
                          >
                            {props.attemptsLeft > 1
                              ? `${props.attemptsLeft} Attempts left`
                              : `${props.attemptsLeft} Attempt left`}
                          </Typography>
                        </Box>
                      )}
                      <Tooltip
                        title={props.attemptsLeft === 0 ? "Maximum attempts reached. Try again in 15 minutes." : ""}
                        arrow
                      >
                        <span>
                        <ButtonFilled
                        title={STRINGS.CREATE_JOB.ENHANCE_JD}
                        onClick={props.handleEnhanceJDClick}
                        disabled={props.attemptsLeft <= 0}
                        sx={{
                          height: "36px",
                          "&.Mui-disabled": {
                            backgroundColor: "#A1A1A1 !important",
                            color: "#c0c0c0 !important",
                          },
                        }}
                      />
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <QuillEditor
                      data-testid="editor"
                      editorStyle={{
                        height: 350,
                      }}
                      label=""
                      disabled={
                        props.formik?.values?.enhanceJd?.length > 0
                          ? false
                          : true
                      }
                      value={
                        props.formik?.values?.enhanceJd
                          ? props.formik?.values?.enhanceJd
                          : ""
                      }
                      onChange={(ev: any) => {
                        props.formik?.setFieldValue("enhanceJd", ev);
                      }}
                    />
                    <Box sx={{ border: "1px solid #ccc", height: "40px", borderTop: "none"}}>

                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            <Box
              sx={{
                height: 60,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
              }}
            >
              <ButtonFilled
                title={STRINGS.CREATE_JOB.NEXT}
                disabled={props.formik?.values?.enhanceJd?.length == 0}
                sx={{
                  width: "66px",
                  height: "36px",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  backgroundColor:
                    props.formik?.values?.enhanceJd?.length == 0
                      ? "#A1A1A1"
                      : "#4A57A3",
                  "&.Mui-disabled": {
                    backgroundColor: "#A1A1A1 !important",
                    color: "#c0c0c0 !important",
                  },
                }}
                onClick={handleNext}
              />
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default EnhanceJD;
