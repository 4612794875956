// React Imports
import { FC, useState, useEffect } from "react";

// UI Imports
import { Box, FormHelperText } from "@mui/material";
import Text from "../../common/Text";
import LabelInput from "../../common/LabelInput";
import LabelInputChipset from "../../common/LabelInputChipst";
import LabelSelectborder from "../../common/LabelSelectborder/LabelSelectborder";
import ButtonFilled from "../../common/ButtonFilled";
import EnhanceJD from "./EnhanceJD";
import API from "../../api/API";
import STRINGS from "../../utils/Strings";
import Toasts from "../../utils/Toasts";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../common/Loader";
import Functions from "../../utils/Functions";
import QuillEditor from "../../common/QuillEditor";
import { Tooltip } from "@mui/material";

interface JobdescriptionProps {
  handleChange?: any;
  handleNext?: any;
  formik?: any;
  isTesting?: boolean;
}

const Jobdescription: FC<JobdescriptionProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [jdOpen, setJdOpen] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [skillError, setSkillError] = useState<string | null>(null);
  const [attemptsLeft, setAttemptsLeft] = useState<number>(0);

  const formikJD = useFormik({
    initialValues: {
      jobTitle: "",
      skillSet: "",
      jobDescription: "",
      ClientName: "Studiopress Corporation",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      jobTitle: Yup.string()
        .required(STRINGS.CREATE_JOB.FILL_REQ_FIELD_TEXT)
        .max(100, "Job title cannot be more than 100 characters")
        .matches(
          /^[a-zA-Z0-9\s.+#,/-]*$/,
          "Only .-+#,/ characters are allowed."
        ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      API.EnhanceJD(values)
        .then((response) => {
          if (response?.status === 200) {
            let data = response?.entity;
            const messageList = data?.messageList;
            if (messageList && messageList.length > 0) {
              let jobDescriptionPass =
                Functions.ValueRetrieve.getJobDescriptionValue(messageList);

              props.formik?.setFieldValue("jobDescription", jobDescriptionPass);
            } else {
              props.formik?.setFieldValue("jobDescription", "");
              if (Number(response?.entity?.remainingAttempts) === 0) {
                Toasts.error(
                  "No Attempt left! please try again after 15 minutes."
                );
              } else {
                Toasts.error(
                  "No details found! please try again after some time."
                );
              }
            }
          }
          props.formik?.setFieldValue(
            "atemptLeft",
            response?.entity?.remainingAttempts
          );
          setAttemptsLeft(response?.entity?.remainingAttempts);
          Functions.LocalStorage.storeString(
            Functions.LocalStorage.KEYS.Remaining_Attemts,
            response.entity?.remainingAttempts
          );
          setLoading(false);
        })
        .catch((e) => {
          Toasts.error(e);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      handleEnhanceJDAPICall();
      handleEnhanceJDClick();
      handleChange({
        target: {
          name: "name",
          value: "value",
        },
      });
      handleDeleteClick();
      handleJdOpen();
      SkillChange(["c", "er"]);

      formikJD
        .setValues({
          jobTitle: "Title",
          skillSet: "skill",
          jobDescription: "Description",
          ClientName: "Studiopress Corporation",
        })
        .then(() => {
          formikJD.handleSubmit();
        });

      let messageList = [
        {
          aboutClient:
            "Refer to the client or company strictly in third person (Critical: Do not use client name, company name or employer name in this section), write a clear description about them including information on cultural values they adopt and their industry competencies.",
          certifications: ["A"],
          description:
            "A detailed description of the job duties and responsibilities required. Include the job shift start and end timings if provided. Include the job assignment type if provided. (Critical: do not use client or company name in the text).",
          domain: {
            keywords: ["A"],
            name: "IT",
          },
          education: ["A"],
          functionalSkills: ["A"],
          industry: {
            keywords: ["A"],
            name: "NAICS Industry name",
          },
          jobTitle: "Java Developer",
          jobType: "R",
          languages: ["A"],
          licenses: ["A"],
          location: "",
          rolesAndResponsibilities: ["A"],
          technicalSkills: ["A"],
        },
      ];
      Functions.ValueRetrieve.getJobDescriptionValue(messageList);
      Functions.ValueRetrieve.getJobDescriptionValue(messageList);
      handleEditorChange("<p><br></p>")
      handleEditorChange("test")
    }
  }, []);

  useEffect(() => {
    formikJD?.setFieldValue("jobTitle", props.formik?.values?.jobTitle);
    formikJD?.setFieldValue(
      "jobDescription",
      props.formik?.values?.jobDescription
    );
    formikJD?.setFieldValue("modeorwork", props.formik?.values?.modeorwork);
    setTags(props.formik?.values?.skillSetarr);

    API.getEnhaceJDattempt().then((response) => {
      Functions.LocalStorage.storeString(
        Functions.LocalStorage.KEYS.Remaining_Attemts,
        response.entity?.remainingAttemts
      );
      setAttemptsLeft(response.entity?.remainingAttemts);
    });
  }, []);

  const handleEnhanceJDClick = () => {
    formikJD.validateField("jobTitle");
    if (formikJD.errors.jobTitle) {
      formikJD.setTouched({ jobTitle: true });
      return;
    }
    setJdOpen(true);
  };

  const handleEnhanceJDAPICall = () => {
    setLoading(true);
    API.EnhanceJD(formikJD.values)
      .then((response) => {
        if (response?.status === 200) {
          let data = response?.entity;
          const messageList = data?.messageList;

          if (messageList && messageList.length > 0) {
            let ejd =
              Functions.ValueRetrieve.getJobDescriptionValue(messageList);
            props.formik?.setFieldValue("enhanceJd", ejd);
          } else {
            props.formik?.setFieldValue("enhanceJd", "");
            if (response?.entity?.remainingAttempts == 0) {
              Toasts.error(
                "No Attempt left! please try again after 15 minutes."
              );
            } else {
              Toasts.error(
                "No details found! please try again after some time."
              );
            }
          }
        }
        setLoading(false);
        props.formik?.setFieldValue(
          "atemptLeft",
          response?.entity?.remainingAttempts
        );
        setAttemptsLeft(response?.entity?.remainingAttempts);
        Functions.LocalStorage.storeString(
          Functions.LocalStorage.KEYS.Remaining_Attemts,
          response.entity?.remainingAttempts
        );
      })
      .catch((e) => {
        Toasts.error(e);
        setLoading(false);
      });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    props.formik?.setFieldValue(name, value);
    formikJD?.setFieldValue(name, value);
  };

  const SkillChange = (tags: string[]) => {
    const combinedLength = tags.reduce((acc, tag) => acc + tag.length, 0);
    if (combinedLength > 2000) {
      setSkillError("Character count exceeded");
    } else {
      setSkillError(null);
      setTags(tags);
      props.formik?.setFieldValue("skillSetarr", tags);
      let skills = tags.join();
      props.formik?.setFieldValue("skillSet", skills);
      formikJD?.setFieldValue("skillSet", skills);
    }
  };

  const handleEditorChange = (ev: any) => {
    if (ev === "<p><br></p>") {
      formikJD?.setFieldValue("jobDescription", "");
      props.formik?.setFieldValue("jobDescription", "");
    } else {
      formikJD?.setFieldValue("jobDescription", ev);
      props.formik?.setFieldValue("jobDescription", ev);
    }
  };

  const handleDeleteClick = () => {
    props.formik?.setFieldValue("jobDescription", "");
  };

  const handleJdOpen = () => {
    formikJD.handleSubmit();
  };

  const getPlaceholder = () => {
    return "Write or paste your job description here, or click 'Generate JD' to create one";
  };

  const isGenerateJD = props.formik.values.jobDescription.length === 0;

  return (
    <Box
      data-testid="job-desc-comp"
      className="job-desc-comp"
      sx={{
        p: 3,
        px: 20,
      }}
    >
      {loading && <Loader />}
      <Text fontWeight={600} fontSize={24}>
        {STRINGS.CREATE_JOB.JD_HEADING_TEXT}
      </Text>
      <Box
        sx={{
          pt: 2,
          textAlign: "left",
        }}
      >
        <LabelInput
          label={STRINGS.CREATE_JOB.JOB_TITLE}
          required={true}
          testId="job-title"
          inputProps={{
            "data-testid": "job-title-input",
          }}
          textFieldProps={{
            placeholder:
              STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.JOB_TITLE,
            sx: {
              width: { xs: "33%", sm: 300 },
              mt: 1,
              backgroundColor: "#F9FAFC",
            },
            id: "jobTitle",
            name: "jobTitle",
            value: props.formik?.values?.jobTitle,
            inputProps: {
              maxLength: 101,
            },
            onChange: handleChange,
            onBlur: formikJD.handleBlur,
            error: Functions.Sonar.booleanAnd(
              formikJD?.touched?.jobTitle,
              Boolean(formikJD?.errors?.jobTitle)
            ),
            helperText: Functions.Sonar.booleanAndString(
              formikJD?.touched?.jobTitle,
              formikJD?.errors?.jobTitle
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          pt: 2,
          textAlign: "left",
        }}
      >
        <Text fontWeight={600} fontSize={16}>
          {STRINGS.CREATE_JOB.JD_OPTIONAL_FIELD_TEXT}{" "}
        </Text>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          data-testid="skill-set-input"
          sx={{
            mt: 1,
            textAlign: "left",
          }}
        >
          <LabelSelectborder
            label={STRINGS.CREATE_JOB.MODE_OF_WORK}
            name="modeofwork"
            id="modeofwork"
            options={[
              { label: "remote", value: "Remote" },
              { label: "onsite", value: "Onsite" },
              { label: "hybrid", value: "Hybrid" },
            ]}
            placeholder="Select"
            onChange={handleChange}
            accessDisplayValue="value"
            accessValue="label"
            setVal={props.formik?.values?.modeofwork}
            sx={{
              width: { xs: "33%", sm: 300 },
              height: 40,
              mt: 1,
              backgroundColor: "#F9FAFC",
            }}
          />
        </Box>
        <Box
          sx={{
            pt: 1,
            textAlign: "left",
          }}
        >
          <LabelInputChipset
            label={STRINGS.CREATE_JOB.SKILL_SET}
            placeholder={STRINGS.CREATE_JOB.SKILL_SET_PLACEHOLDER}
            sx={{
              width: { xs: "33%", sm: 300 },
              px: 2,
            }}
            tags={tags}
            onChange={SkillChange}
            maxLength={2000}
          />
          {skillError && (
            <FormHelperText
              style={{ color: "#F81919", marginTop: 25, marginLeft: 20 }}
            >
              {skillError}
            </FormHelperText>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          pt: 2,
          textAlign: "left",
          height: "250px",
        }}
        data-testid="job-description-input"
      >
        <QuillEditor
          label={STRINGS.CREATE_JOB.JOD_DESCRIPTION}
          onChange={(ev: any) => handleEditorChange(ev)}
          value={props.formik?.values?.jobDescription}
          isdelete={true}
          handleDeleteClick={handleDeleteClick}
          placeholder={getPlaceholder()}
          sx={{
            height: "150px",
          }}
        />
        {!isGenerateJD && (
          <Text fontSize={14} style={{ marginTop: 50 }}>
            {STRINGS.CREATE_JOB.ENHANCE_JD_NOTE}
          </Text>
        )}
      </Box>
      <Box
        sx={{
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }}
      >
        <EnhanceJD
          jobDescription={props.formik.values?.jobDescription}
          handleEditorChange={(ev: any) => handleEditorChange(ev)}
          isjdExpanded={jdOpen}
          formikJD={formikJD}
          setIsjdExpanded={setJdOpen}
          formik={props.formik}
          handleNext={props.handleNext}
          handleEnhanceJDClick={handleEnhanceJDAPICall}
          attemptsLeft={attemptsLeft}
          loading={loading}
        />
        {props.formik.values.jobDescription.length == 0 && (
          <Tooltip
            title={
              attemptsLeft === 0
                ? "Maximum attempts reached. Try again in 15 minutes."
                : ""
            }
            arrow
          >
            <span>
              <ButtonFilled
                title={STRINGS.CREATE_JOB.GENRATE_JD}
                onClick={handleJdOpen}
                testId="generate-jd-button"
                disabled={attemptsLeft == 0 || attemptsLeft == null}
                sx={{
                  width: 121,
                  height: 36,
                  backgroundColor:
                    attemptsLeft == 0 || attemptsLeft == null
                      ? "#A1A1A1"
                      : "#4A57A3",
                  "&.Mui-disabled": {
                    backgroundColor: "#A1A1A1 !important",
                    color: "#c0c0c0 !important",
                  },
                }}
              />
            </span>
          </Tooltip>
        )}
        {props.formik.values.jobDescription.length > 0 && (
          <Tooltip
            title={
              attemptsLeft === 0
                ? "Maximum attempts reached. Try again in 15 minutes."
                : ""
            }
            arrow
          >
            <span>
              <ButtonFilled
                title={STRINGS.CREATE_JOB.ENHANCE_JD}
                onClick={handleEnhanceJDClick}
                testId="generate-jd-button"
                disabled={attemptsLeft == 0 || attemptsLeft == null}
                sx={{
                  width: 121,
                  height: 36,
                  backgroundColor:
                    attemptsLeft == 0 || attemptsLeft == null
                      ? "#A1A1A1"
                      : "#4A57A3",
                  "&.Mui-disabled": {
                    backgroundColor: "#A1A1A1 !important",
                    color: "#c0c0c0 !important",
                  },
                }}
              />
            </span>
          </Tooltip>
        )}

        <ButtonFilled
          title={STRINGS.CREATE_JOB.NEXT}
          disabled={
            props.formik?.values?.jobTitle.length == 0 ||
            props.formik?.values?.jobDescription.length == 0
          }
          sx={{
            width: "66px",
            height: "36px",
            padding: "8px 16px",
            borderRadius: "4px",
            // boxShadow: "2px",
            backgroundColor:
              props.formik?.values?.jobTitle.length == 0 ||
              props.formik?.values?.jobDescription.length == 0
                ? "#A1A1A1"
                : "#4A57A3",
            "&.Mui-disabled": {
              backgroundColor: "#A1A1A1 !important",
              color: "#c0c0c0 !important",
            },
          }}
          testId="next-button"
          onClick={props.handleNext}
        />
      </Box>
    </Box>
  );
};

export default Jobdescription;
