// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, ButtonBase, IconButton } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";
import { FaAngleUp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Paths from "../../routes/Paths";

interface HierarchyRowProps {
  jobTitle?: string;
  jobId?: string;
  jobLocation?: string;
  jobPostedDate?: string;
  noOfApplication?: string | number;
  expanded?: boolean;
  setExpanded?: any;

  isTesting?: boolean;
  testId?: string;
}

const HierarchyRow: FC<HierarchyRowProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      handleExpanded();
    }
  }, []);

  const handleExpanded = () => props.setExpanded(!props.expanded);

  return (
    <Box
      data-testid={props.testId}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        backgroundColor: Colors.White,
        pl: 3,
        pr: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <IconButton
          disabled={Number(props.noOfApplication) <= 0}
          onClick={handleExpanded}
        >
          {props.expanded && <FaAngleUp />}
          {!props.expanded && <FaAngleDown />}
        </IconButton>

        <Link to={Paths.JOB + Paths.VIEW_JOB + "/" + props.jobId}>
          <Text color={Colors.Blue7} fontWeight={500} fontSize={16}>
            {props.jobTitle}
          </Text>
        </Link>
        <Text fontSize={16} color={Colors.Grey6}>
          |
        </Text>
        <Text fontSize={16} color={Colors.Grey6}>
          {props.jobId}
        </Text>

        <Text fontSize={16} color={Colors.Grey6}>
          |
        </Text>
        <Text fontSize={16} color={Colors.Grey6}>
          {props.jobLocation}
        </Text>

        <Text fontSize={16} color={Colors.Grey6}>
          |
        </Text>
        <Text fontSize={16} color={Colors.Grey6}>
          {props.jobPostedDate}
        </Text>
      </Box>
      <ButtonBase
        disabled={Number(props.noOfApplication) <= 0}
        onClick={handleExpanded}
      >
        <Text
          style={{
            height: 28,
            width: 28,
            borderRadius: 8,
            backgroundColor: Colors.Blue7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          fontSize={16}
          fontWeight={700}
          color={Colors.White}
        >
          {props.noOfApplication}
        </Text>
      </ButtonBase>
    </Box>
  );
};

export default HierarchyRow;
