// React Imports
import { FC, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports

// Component Imports
import ApplicationHierarchyList from "./ApplicationHierarchyList";
import HierarchyRow from "./HierarchyRow";
import Text from "../../common/Text";
import Colors from "../../utils/Colors";
import { Link } from "react-router-dom";
import Paths from "../../routes/Paths";

interface HierarchyBlockProps {
  jobId?: any;
  jobTitle?: any;
  jobLocation?: any;
  jobPostedDate?: any;
  jobApplications?: any;

  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;

  expanded?: boolean;
  setExpanded?: any;
  showMore?: boolean;

  testId?: string;
}

const HierarchyBlock: FC<HierarchyBlockProps> = (props) => {
  let jobApplicationCount = 0;
  let jobApplications = [];

  if (props.jobApplications?.length) {
    jobApplicationCount = props.jobApplications?.length;
  }

  if (props.jobApplications) {
    jobApplications = props.jobApplications;
  }

  return (
    <Box data-testid={props.testId}>
      <HierarchyRow
        jobId={props.jobId}
        jobTitle={props.jobTitle}
        jobLocation={props.jobLocation}
        jobPostedDate={props.jobPostedDate}
        noOfApplication={jobApplicationCount}
        expanded={props.expanded}
        setExpanded={props.setExpanded}
      />
      {props.expanded && (
        <ApplicationHierarchyList
          pageIndex={0}
          pageSize={0}
          tableData={jobApplications}
          hideFooter
          sx={{
            height: "auto",
            width: "95%",
          }}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
          onShare={props.onShare}
          onHire={props.onHire}
          onReject={props.onReject}
          onResumeOpen={props.onResumeOpen}
          onScheduleInterviewOpen={props.onScheduleInterviewOpen}
        />
      )}
      {props.expanded && (props.showMore || true) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mr: 4,
            my: 1,
          }}
        >
          <Link
            to={
              Paths.JOB +
              Paths.VIEW_JOB +
              "/" +
              props.jobId +
              "?opentab=applications"
            }
          >
            <Text fontWeight={600} fontSize={16} color={Colors.Blue1}>
              Show more...
            </Text>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default HierarchyBlock;
