// React Imports
import { FC, useState, useEffect } from "react";

// UI Imports
import {
  Box,
  Grid,
  Divider,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import API from "../../api/API";

// Component Imports
import LabelSelectborder from "../../common/LabelSelectborder";
import LabelInputChipset from "../../common/LabelInputChipst";
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";
import "./EditJob.css";
import QuillEditor from "../../common/QuillEditor";
import Loader from "../../common/Loader";

const interviewOption = [
  { label: 2, value: "Telephone" },
  { label: 4, value: "Personal" },
  { label: 8, value: "Webcam" },
  { label: 16, value: "No Interview Required" },
];
const employmentOption = [
  { label: "C", value: "Contract" },
  { label: "F", value: "Full Time" },
  { label: "R", value: "Right to hire" },
];

interface JobdetailProps {
  formik?: any;
  isTesting?: any;
}

const Jobdetail: FC<JobdetailProps> = (props) => {
  const [zipTimeout, setZipTimeout] = useState<any>(null);
  const [jobTitleError, setJobTitleError] = useState<string | null>(null);
  const [skillError, setSkillError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [cityoption, setCityoption] = useState<string[]>([]);
  const [showcity, setShowcity] = useState(false);


  useEffect(() => {
    if (props.isTesting) {
      SkillChange(["D"]);
      handleChange({ target: { name: "name", value: "value" } });
      handleZipchange({ target: { name: "name", value: "1234" } });
      handleZipchange({ target: { name: "name", value: "12345" } });
      handleCKchange({}, { getData: () => "A" });
      populateZipCode("12345");
      populateZipCode("1234");
      handleCitychange({
        target: { value: "Noida" },
      })
      handleCitychange({
        target: { value: "" },
      })
      cityselect(78987)
      cityselect(0)
      handleZipApiError({})
      let strTest = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      validateJobTitle("A");
      validateJobTitle("$");
      validateJobTitle(
        strTest + strTest + strTest + strTest + strTest + strTest
      );
      handleJobTitleChange({ target: { name: "jobTitle", value: "value" } });
    }
  }, []);

  const SkillChange = (tags: string[]) => {
    const combinedLength = tags.reduce((acc, tag) => acc + tag.length, 0);
    if (combinedLength > 2000) {
      setSkillError("Character count exceeded");
    } else {
      setSkillError(null);
      props.formik?.setFieldValue("skillarr", tags);
      let skills = tags.join();
      props.formik?.setFieldValue("skillSet", skills);
    }
  };

  const handleChange = (ev: any) => {
    props.formik?.setFieldValue(ev.target.name, ev.target.value);
  };

  const handleZipchange = (ev: any) => {
    let zipcode = ev.target.value.replace(/[^a-zA-Z0-9]/g, "");
    if (zipcode.length <= 6) {
      props.formik?.setFieldValue("zipCode", zipcode);

      if (zipTimeout) {
        clearTimeout(zipTimeout);
      }

      const newTimeout = setTimeout(() => {
        populateZipCode(zipcode);
      }, 500);

      setZipTimeout(newTimeout);
    }
  };

  const handleJobTitleChange = (ev: any) => {
    const { name, value } = ev.target;
    if (name === "jobTitle") {
      validateJobTitle(value);
    }
    props.formik?.setFieldValue(name, value);
  };

  const validateJobTitle = (value: string) => {
    const maxLength = 100;
    const regex = /^[a-zA-Z0-9\s.+#,/-]*$/;
    if (value.length > maxLength) {
      setJobTitleError("Job title cannot be more than 100 characters");
    } else if (!regex.test(value)) {
      setJobTitleError("Only .-+#,/ characters are allowed.");
    } else {
      setJobTitleError(null);
    }
  };

  const handleCKchange = (event: any, editor: any) => {
    let jobdes: any | null = editor.getData();
    jobdes = jobdes.replace("<p><br>&nbsp;</p>", "");
    props.formik?.setFieldValue("jobDescription", jobdes.trim());
  };

  let timeoutId: any;
  function debounce(cb: any, delay: number = 500) {
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }
  const handleCitychange = (ev: any) => {
    if (ev.target.value != '') {
     
      let citydata: any = [];
      let searchstr = ev.target.value;
      API.Cityauto(searchstr)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            citydata = response.entity
          }
          setShowcity(true)
          setCityoption(citydata);
        })
        .catch((e) => {
          handleZipApiError(e);
        });

    }
    else {
      setShowcity(false)
      setCityoption([]);
      props.formik?.setFieldValue("stateCode", "");
      props.formik?.setFieldValue("countryCode","");
    }
  }
  const citysearch = debounce(handleCitychange, 500)
  const cityselect = (cityid: number) => {
    if (cityid) {
      setLoading(true);
      API.Citydata(cityid)
        .then((response) => {
          console.log(response)
          let locatindata: any =
            response.entity;
          if (response.status === 200) {
            props.formik?.setFieldValue("city", locatindata?.cityName);
            props.formik?.setFieldValue("stateName", locatindata?.stateName);
            props.formik?.setFieldValue("countryName", locatindata?.countryName);
            props.formik?.setFieldValue("stateId", locatindata?.stateId);
            props.formik?.setFieldValue("countryId", locatindata?.countryId);
            props.formik?.setFieldValue("cityId", locatindata?.cityId);
            props.formik?.setFieldValue("stateCode", locatindata?.stateCode);
            props.formik?.setFieldValue("countryCode",locatindata?.countryCode);

            props.formik?.setTouched({
              ...props.formik.touched,
              city: false,
              stateName: false,
              countryName: false,
            });

          } else if (response.status === 404) {
            Toasts.error("No data available for this Zip.");
            props.formik?.setTouched({
              ...props.formik.touched,
              city: true,
              stateName: true,
              zipCode: true,
              countryName: true,
            });
            props.formik?.setFieldValue("city", "");
            props.formik?.setFieldValue("stateName", "");
            props.formik?.setFieldValue("countryName", "");
            props.formik?.setFieldValue("stateId", "");
            props.formik?.setFieldValue("countryId", "");
            props.formik?.setFieldValue("cityId", "");
            props.formik?.setFieldValue("stateCode", "");
            props.formik?.setFieldValue("countryCode","");
          }
          setLoading(false);
          setShowcity(false)
        })
        .catch((e) => {
          handleZipApiError(e);
        });
    } else {
      props.formik?.setFieldValue("city", "");
      props.formik?.setFieldValue("stateName", "");
      props.formik?.setFieldValue("countryName", "");
      props.formik?.setFieldValue("stateId", "");
      props.formik?.setFieldValue("countryId", "");
      props.formik?.setFieldValue("cityId", "");
      props.formik?.setFieldValue("stateCode", "");
      props.formik?.setFieldValue("countryCode","");
      setLoading(false);
      setShowcity(false)
    }
  }
  const populateZipCode = (zipcode: any) => {
   if (String(zipcode).length >= 5) {
      setLoading(true);
      API.Zipcity(zipcode)
        .then((response) => {
          console.log(response)
          let locatindata: any =
          response.entity;
          if (response.status === 200) {
            props.formik?.setFieldValue("city", locatindata?.cityName);

            props.formik?.setFieldValue("stateName", locatindata?.stateName);
            props.formik?.setFieldValue("countryName",locatindata?.countryName);
            props.formik?.setFieldValue("stateId", locatindata?.stateId);
            props.formik?.setFieldValue("countryId",locatindata?.countryId);
            props.formik?.setFieldValue("stateCode", locatindata?.stateCode);
            props.formik?.setFieldValue("countryCode",locatindata?.countryCode);
            props.formik?.setFieldValue("cityId", locatindata?.cityId);


            props.formik?.setTouched({
              ...props.formik?.touched,
              city: false,
              stateName: false,
              countryName: false,
            });
           }else if (response.status === 404) {
            Toasts.error("No data available for this Zip.");
            props.formik?.setTouched({
              ...props.formik?.touched,
              city: true,
              stateName: true,
              zipCode: true,
              countryName: true,
            });
            props.formik?.setFieldValue("city", "");
            props.formik?.setFieldValue("stateName", "");
            props.formik?.setFieldValue("countryName", "");
            props.formik?.setFieldValue("stateId",  "");
            props.formik?.setFieldValue("countryId", "");
            props.formik?.setFieldValue("cityId",  "");
            props.formik?.setFieldValue("stateCode", "");
            props.formik?.setFieldValue("countryCode","");
          }
          setLoading(false);
        })
        .catch((e) => {
          handleZipApiError(e);
        });
    } else {
      props.formik?.setFieldValue("city", "");
      props.formik?.setFieldValue("stateName", "");
      props.formik?.setFieldValue("countryName", "");
      props.formik?.setFieldValue("stateId",  "");
      props.formik?.setFieldValue("countryId", "");
      props.formik?.setFieldValue("cityId",  "");
      props.formik?.setFieldValue("stateCode", "");
      props.formik?.setFieldValue("countryCode","");
      setLoading(false);
    }
  };
  const handleZipApiError = (e: any) => {
    Toasts.error(e);
    props.formik?.setFieldValue("city", "");
    props.formik?.setFieldValue("stateName", "");
    props.formik?.setFieldValue("countryName", "");
    props.formik?.setFieldValue("stateId",  "");
    props.formik?.setFieldValue("countryId", "");
    props.formik?.setFieldValue("cityId",  "");

    props.formik?.setTouched({
      ...props.formik?.touched,
      city: true,
      stateName: true,
      zipCode: true,
      countryName: true,
    });
    setLoading(false);
  };
  return (
    <Box data-testid="edit-job-detail" className="edit-job-detail" onClick={()=>{setShowcity(false)}} >
    
            {loading && <Loader />}

      <Box
        sx={{
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text fontWeight={700} fontSize={16}>
          {STRINGS.CREATE_JOB.REQUIRED_DETAIL}
        </Text>
      </Box>
      <Box
        className="detailsection"
        sx={{
          width: "100%",
          mb: 8,
        }}
        
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3} md={3} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.JOB_TITLE}
              required={true}
              textFieldProps={{
                inputProps: {
                  "data-testid": "job-title-input",
                },
                placeholder:
                  STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.JOB_TITLE,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                },
                id: "jobTitle",
                name: "jobTitle",
                value: props.formik?.values?.jobTitle,
                // onChange: (ev) => {
                //   props.formik?.setFieldValue("jobTitle", ev.target.value);
                // },
                onChange: handleJobTitleChange,
                onBlur: props.formik?.handleBlur,
                error: Boolean(jobTitleError),
                helperText: jobTitleError,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.NUMBER_OF_OPENING}
              required={true}
              textFieldProps={{
                inputProps: {
                  "data-testid": "no-of-openings-input",
                },
                placeholder: STRINGS.CREATE_JOB.NUMBER_OF_OPENING,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                },
                id: "numberOfOpenings",
                name: "numberOfOpenings",
                value: props.formik?.values?.numberOfOpenings,
                onChange: (ev) => {
                  const value = ev.target.value.replace(/[^0-9]/g, "");
                  if (value.length <= 4) {
                    props.formik?.setFieldValue(
                      "numberOfOpenings",
                      ev.target.value.replace(/[^0-9]/g, "")
                    );
                  }
                },
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.numberOfOpenings,
                  Boolean(props.formik?.errors?.numberOfOpenings)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.numberOfOpenings,
                  props.formik?.errors?.numberOfOpenings
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.ZIP}
              required={true}
              textFieldProps={{
                placeholder: STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.ZIP,
                inputProps: {
                  "data-testid": "zip-input",
                },
                sx: {
                  mt: 1,
                  width: { xs: "100%" },
                  minHeight: "75px",
                },
                name: "zipCode",
                id: "zipCode",
                value: props.formik?.values?.zipCode,
                onChange: (ev: any) => {
                  handleZipchange(ev);
                },
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.zipCode,
                  Boolean(props.formik?.errors?.zipCode)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.zipCode,
                  props.formik?.errors?.zipCode
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={2}>
          <div className="citycontainer">

            <LabelInput
              label={STRINGS.CREATE_JOB.CITY}
              
              required={true}
              textFieldProps={{
                inputProps: {
                  "data-testid": "city-input",
                },
                placeholder: "Search for city",
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                },
                id: "city",
                name: "city",
                onChange: (ev) => {
                  props.formik?.setFieldValue("city", ev.target.value);
                  citysearch(ev);

                },
                value: props.formik?.values?.city,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.city,
                  Boolean(props.formik?.errors?.city)
                ),
                onBlur: props.formik?.handleBlur,
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.city,
                  props.formik?.errors?.city
                ),
              }}
            />
            {showcity && (
                <ul className="autoSearchList_city">
                  {cityoption.length > 0 && (cityoption.map((option: any) => (<li key={option.cityId} onClick={() => cityselect(option.cityId)}>{option.cityName}</li>)))}

                </ul>
              )}
              </div>
          </Grid>
          <Grid item xs={3} md={3} mt={2}>
            <LabelSelectborder
              label={STRINGS.CREATE_JOB.EMPLOYMENT_TYPE}
              required={true}
              options={employmentOption}
              placeholder="Select"
              onChange={handleChange}
              name="jobType"
              id="jobType"
              setVal={
                props.formik?.values?.jobType
                  ? props.formik?.values?.jobType
                  : ""
              }
              accessValue="label"
              accessDisplayValue="value"
              sx={{
                minHeight: "75px",
                mt: 1,
                width: { xs: "100%" },
                p: 0,
              }}
              error={Functions.Sonar.booleanAnd(
                props.formik?.touched?.jobType,
                Boolean(props.formik?.errors?.jobType)
              )}
              handleBlur={props.formik?.handleBlur}
              helperText={Functions.Sonar.booleanAndString(
                props.formik?.touched?.jobType,
                props.formik?.errors?.jobType
              )}
            />
          </Grid>

          {(props.formik?.values?.jobType == "C" ||
            props.formik?.values?.jobType == "R") && (
            <Grid item xs={3} md={3} mt={2}>
              <LabelInput
                label={STRINGS.CREATE_JOB.BILL_RATE}
                required={true}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                textFieldProps={{
                  inputProps: {
                    "data-testid": "bill-rate-input",
                  },
                  placeholder:
                    STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.BILL_RATE,
                  sx: {
                    width: { xs: "100%" },
                    mt: 1,
                  },
                  name: "billRate",
                  id: "billRate",
                  onChange: (ev) => {
                    let regex = /^[0-9]{0,4}(\.[0-9]{0,2})?$/;
                    if (ev.target.value === "" || regex.test(ev.target.value)) {
                      props.formik?.setFieldValue("billRate", ev.target.value);
                    }
                  },
                  value: props.formik?.values?.billRate,
                  error: Functions.Sonar.booleanAnd(
                    props.formik?.touched?.billRate,
                    Boolean(props.formik?.errors?.billRate)
                  ),
                  onBlur: props.formik?.handleBlur,
                  helperText: Functions.Sonar.booleanAndString(
                    props.formik?.touched?.billRate,
                    props.formik?.errors?.billRate
                  ),
                }}
              />
            </Grid>
          )}
          {(props.formik?.values?.jobType == "R" ||
            props.formik?.values?.jobType == "F") && (
            <Grid item xs={3} md={3} mt={2}>
              <LabelInput
                label={STRINGS.CREATE_JOB.ANNUALSALARY}
                required={true}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                textFieldProps={{
                  inputProps: {
                    "data-testid": "annual-salary-input",
                  },
                  placeholder:
                    STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.ANNUALSALARY,
                  sx: {
                    mt: 1,
                    width: { xs: "100%" },
                  },
                  name: "salaryRange",
                  id: "salaryRange",
                  onChange: (ev) => {
                    let regex = /^[0-9]{0,10}(\.[0-9]{0,2})?$/;
                    if (ev.target.value === "" || regex.test(ev.target.value)) {
                      props.formik?.setFieldValue(
                        "salaryRange",
                        ev.target.value
                      );
                    }
                  },
                  value: props.formik?.values?.salaryRange,
                  error: Functions.Sonar.booleanAnd(
                    props.formik?.touched?.salaryRange,
                    Boolean(props.formik?.errors?.salaryRange)
                  ),
                  onBlur: props.formik?.handleBlur,
                  helperText: Functions.Sonar.booleanAndString(
                    props.formik?.touched?.salaryRange,
                    props.formik?.errors?.salaryRange
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={3} md={3} mt={2}>
            <LabelSelectborder
              label={STRINGS.CREATE_JOB.INTERVIEW_MODE}
              required={true}
              accessDisplayValue="value"
              accessValue="label"
              setVal={
                props.formik?.values?.interviewtype
                  ? props.formik?.values?.interviewtype
                  : ""
              }
              options={interviewOption}
              placeholder="Select"
              onChange={handleChange}
              sx={{
                width: { xs: "100%" },
                mt: 1,
                height: 40,
                p: 0,
              }}
              id="interviewtype"
              name="interviewtype"
              handleBlur={props.formik?.handleBlur}
              error={Functions.Sonar.booleanAnd(
                props.formik?.touched?.interviewtype,
                Boolean(props.formik?.errors?.interviewtype)
              )}
              helperText={Functions.Sonar.booleanAndString(
                props.formik?.touched?.interviewtype,
                props.formik?.errors?.interviewtype
              )}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={2}>
            <LabelSelectborder
              label={STRINGS.CREATE_JOB.MODE_OF_WORK}
              options={[
                { label: "remote", value: "Remote" },
                { label: "onsite", value: "Onsite" },
                { label: "hybrid", value: "Hybrid" },
              ]}
              placeholder="Select"
              onChange={handleChange}
              accessDisplayValue="value"
              accessValue="label"
              name="modeofwork"
              setVal={
                props.formik?.values?.modeofwork
                  ? props.formik?.values?.modeofwork
                  : ""
              }
              sx={{
                width: { xs: "100%" },
                mt: 1,
                height: 40,
                p: 0,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={2} mb={2}>
            <LabelInputChipset
              label={STRINGS.CREATE_JOB.SKILL_SET}
              sx={{
                width: { xs: "100%" },
                mt: 1,
                maxHeight: 40,
              }}
              tags={
                props.formik?.values?.skillarr
                  ? props.formik?.values?.skillarr
                  : []
              }
              onChange={SkillChange}
              maxLength={2000}
            />
            {skillError && (
              <FormHelperText style={{ color: "#F81919", marginTop: 25 }}>
                {skillError}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={3} md={3} mt={2}></Grid>
          <Grid item xs={3} md={3} mt={2}></Grid>
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Grid
            item
            xs={6}
            md={6}
            mt={2}
            className={
              props.formik?.values?.jobDescription?.length == 0 ? "errorCK" : ""
            }
          >
            <Text fontWeight={700} fontSize={16}>
              {STRINGS.CREATE_JOB.JOD_DESCRIPTION}
            </Text>
            <QuillEditor
              sx={{
                mt: 2,
              }}
              label={"Description"}
              required={true}
              isdownload={false}
              iscopy={false}
              onChange={(ev: any) => {
                if (ev === "<p><br></p>") {
                  props.formik?.setFieldValue("jobDescription", "");
                } else {
                  props.formik?.setFieldValue("jobDescription", ev);
                }
              }}
              value={props.formik?.values?.jobDescription}
            />
            {props.formik?.values?.jobDescription?.length == 0 && (
              <Text color={Colors.Red} fontWeight={400} fontSize={12}>
                {"This field is required"}
              </Text>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Jobdetail;
