// React Imports
import { FC, useState } from "react";

// UI Imports
import { Avatar, Box, ButtonBase, IconButton, Modal } from "@mui/material";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";

// Functional Imports
import Images from "../../utils/Images";
import Colors from "../../utils/Colors";

// Component Imports
import ProfileActions from "./ProfileActions";
import Text from "../../common/Text";
import Functions from "../../utils/Functions";

interface HeaderProps {
  isDashboard?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const userName = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.USER_NAME
  );

  return (
    <Box
      data-testid="header-comp"
      sx={{
        height: "10vh",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: Colors.White,
        alignItems: "center",
        position: "relative",
        borderBottom: "1px solid " + Colors.Grey2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.isDashboard && (
          <IconButton
            sx={{
              ml: 2,
              mr: -3,
            }}
          >
            <IoMenu />
          </IconButton>
        )}
        <Box
          component={"img"}
          src={Images.VelocityLogo}
          sx={{
            height: 50,
            // marginLeft: 2,
            pl: 4,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginRight: 4,
          pr: 5,
        }}
      >
        <Box
          component={"img"}
          src={Images.BellIcon}
          sx={{
            width: 24,
          }}
        />

        <ButtonBase
          data-testid="show-hide-actions"
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            height: 50,
            ml: 1,
          }}
        >
          <Avatar
            sx={{
              height: 35,
              width: 35,
              marginLeft: 2,
              marginRight: 1,
            }}
          >
            {userName?.charAt(0)}
          </Avatar>
          <Text
            fontWeight={700}
            fontSize={16}
            style={{
              marginRight: 8,
              marginLeft: 8,
            }}
          >
            {userName}
          </Text>
          <>
            {isExpanded && <FaCaretUp size={24} color={Colors.Black1} />}
            {!isExpanded && <FaCaretDown size={24} color={Colors.Black1} />}
          </>
        </ButtonBase>
      </Box>
      <Modal open={isExpanded} onClose={() => setIsExpanded(false)}>
        <ProfileActions />
      </Modal>
    </Box>
  );
};

export default Header;
