// React Imports
import { FC } from "react";

// Router Imports
import { Navigate, Route, Routes as Router } from "react-router-dom";
import Paths from "./Paths";

// Page Imports
import Viewapplication from "../components/ApplicationDetails";
import Applications from "../components/Applications";
import CreateJob from "../components/CreateJob";
import AllJobs from "../components/AllJobs";
import EditJob from "../components/EditJob";
import ViewJob from "../components/ViewJob";
import Layout from "../components/Layout";
import ProjectListing from "../components/ProjectListing";
import Dashboard from "../components/Dashboard";

interface AuthenticatedRoutesProps {}

const AuthenticatedRoutes: FC<AuthenticatedRoutesProps> = (props) => {
  return (
    <div data-testid="authenticated-routes">
      <Router>
        <Route path={Paths.SLASH} element={<Layout />}>
          <Route path={Paths.APPLICATIONS} element={<Applications />} />
          <Route
            path={Paths.APPLICATIONS + Paths.APP_ID}
            element={<Viewapplication />}
          />
          <Route path={Paths.JOBS} element={<AllJobs />} />
          <Route path={Paths.JOB + Paths.CREATE_JOB} element={<CreateJob />} />
          <Route
            path={Paths.JOB + Paths.EDIT_JOB + Paths.JOB_ID}
            element={<EditJob />}
          />
          <Route
            path={Paths.JOB + Paths.VIEW_JOB + Paths.JOB_ID}
            element={<ViewJob />}
          />
          <Route path={Paths.PROJECTS} element={<ProjectListing />} />
        </Route>
        <Route path={Paths.DASHBOARD} element={<Dashboard />} />
        <Route path={"*"} element={<Navigate to={Paths.APPLICATIONS} />} />
      </Router>
    </div>
  );
};

export default AuthenticatedRoutes;
