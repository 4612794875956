// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, Modal } from "@mui/material";

// Functional Imports

// Component Imports
import ProposeTimeSlots from "../../components/ProposeTimeSlots";
import ENUMS from "../../utils/Enums";

interface ModalForProposeTimeSlotProps {
  scheduleInterviewOpen: boolean;
  setScheduleInterviewOpen?: any;
  id?: string;
  resumeVLNStatus?: string;
  candidateName?: any;
  candidateEmail?: any;
  currentAvailabilities?: any;
  updateDataAgain?: any;
  setUpdateDataAgain?: any;
  availabilities?: any;
  setAvailabilities?: any;
  slotId?: any;
  setSlotId?: any;
  description?: any;
  setDescription?: any;
  modifyMode?: any;
  isTesting?: boolean;
  isFromInterviewDetail?: any;
}

const ModalForProposeTimeSlot: FC<ModalForProposeTimeSlotProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      checkAvailabilities();
      handleCloseProposeSlot();
    }
  }, []);

  useEffect(() => {
    checkAvailabilities();
  }, [props.currentAvailabilities, props.resumeVLNStatus]);

  const checkAvailabilities = () => {
    if (
      props.currentAvailabilities &&
      (props.resumeVLNStatus ===
        ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_REQUESTED ||
        props.resumeVLNStatus ===
        ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_SCHEDULED)
    ) {
      const activeAvailabilities = props.currentAvailabilities?.filter(
        (availability: any) => availability.isActive
      );

      const slotId = activeAvailabilities.length > 0 ? activeAvailabilities[0].id : null;

      if (activeAvailabilities?.length > 0) {
        props.setAvailabilities(
          activeAvailabilities?.[0]?.currentAvailabilities
        );
        props.setSlotId(slotId);
        props.setDescription(activeAvailabilities?.[0]?.description);
      }
    }
  };

  const handleCloseProposeSlot = () => {
    props.setScheduleInterviewOpen(false);
  };

  const handleRefresh = () => {
    props.setUpdateDataAgain(!props.updateDataAgain);
  };

  return (
    <Modal open={props.scheduleInterviewOpen} onClose={handleCloseProposeSlot}>
      <Box
        data-testid="propose-time-slot-modal"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          bgcolor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <ProposeTimeSlots
            initialSlotForProps={[null, null, null]}
            onCancel={handleCloseProposeSlot}
            submissionID={props.id}
            candidateName={props.candidateName}
            candidateEmail={props.candidateEmail}
            initialSlots={
              props.resumeVLNStatus === "Interview Requested" ||
                props.resumeVLNStatus === "Interview Scheduled" ||
                props.resumeVLNStatus === "Hired" ||
                props.resumeVLNStatus === "Rejected"
                ? props.availabilities
                : []
            }
            modifyMode={
              props.resumeVLNStatus === "Interview Requested" ||
              props.resumeVLNStatus === "Interview Scheduled" ||
              props.resumeVLNStatus === "Hired" ||
              props.resumeVLNStatus === "Rejected"
            }
            slotId={props.slotId}
            description={props.description}
            setDescription={props.setDescription}
            onRefresh={handleRefresh}
            isFromInterviewDetail={props.isFromInterviewDetail}
            resumeVLNStatus={props.resumeVLNStatus}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalForProposeTimeSlot;
