// React Imports
import { FC, useEffect } from "react";

// UI Imports
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { Oval } from "react-loader-spinner";

// Functional Imports
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";
import API from "../../api/API";

// Component Imports
import ButtonOutlined from "../../common/ButtonOutlined";
import CheckboxLabel from "../../common/CheckboxLabel";
import ButtonFilled from "../../common/ButtonFilled";
import FilterName from "../../common/FilterName";
import Text from "../../common/Text";

interface FilterPopupProjectsProps {
  isExpanded: boolean;
  setIsExpanded?: any;

  selectedFilter?: any;
  setSelectedFilter?: any;
  searchText?: any;
  setSearchText?: any;
  dataLoading?: any;
  setDataLoading?: any;
  onResetAll?: any;
  onApply?: any;
  anchorEl?: any;

  isTesting?: boolean;
}

const FilterPopupProjects: FC<FilterPopupProjectsProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      resetFilterForType();
      handleClose();
    }
  }, []);

  const resetFilterForType = () => {
    console.log("Code Reachable! Sonar");
  };

  const handleClose = () => props.setIsExpanded(false);

  const id = props.isExpanded ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={props.isExpanded}
      anchorEl={props.anchorEl}
      onClose={handleClose}
    >
      <Box
        data-testid="filters-projects-popup"
        sx={{
          width: 370,
          top: 160,
          zIndex: 10,
          left: 100,
          backgroundColor: Colors.White,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            Filters
          </Text>
          <IconButton onClick={handleClose} data-testid="close-modal-btn">
            <IoClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: 300,
              width: "45%",
              backgroundColor: Colors.Grey11,
            }}
          >
            <FilterName title="Filter Name 1" />
            <FilterName title="Filter Name 2" />
            <FilterName title="Filter Name 3" />
            <FilterName title="Filter Name 4" />
            <FilterName title="Filter Name 5" />
          </Box>
          <Box
            sx={{
              width: "55%",
              height: 300,
              backgroundColor: Colors.White,
              // border: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                margin="dense"
                sx={{
                  paddingRight: 0,
                  fontFamily: "Roboto",
                  backgroundColor: Colors.White,
                  "& .MuiInputBase-root": { height: "34px" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoMdSearch size={20} />
                    </InputAdornment>
                  ),
                }}
                value={props.searchText}
                onChange={(ev) => {
                  props.setSearchText(ev.target.value);
                }}
              />
              <IconButton
                data-testid="search-empty-btn"
                onClick={() => resetFilterForType()}
                sx={{
                  height: 32,
                  width: 32,
                  mt: "4px",
                  borderRadius: 0.5,
                  border: "1px solid " + Colors.Grey10,
                }}
              >
                <GrPowerReset />
              </IconButton>
            </Box>
            <Box
              sx={{
                // border: 2,
                overflowY: "auto",
                height: 260,
              }}
            >
              <CheckboxLabel title={"Item 1"} />
              <CheckboxLabel title={"Item 2"} />
              <CheckboxLabel title={"Item 3"} />
              <CheckboxLabel title={"Item 4"} />
              <CheckboxLabel title={"Item 5"} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: 60,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            px: 2,
            gap: 2,
          }}
        >
          <ButtonOutlined title="Reset All" onClick={props.onResetAll} />
          <ButtonFilled title="Apply" onClick={props.onApply} />
        </Box>
      </Box>
    </Popover>
  );
};

export default FilterPopupProjects;
